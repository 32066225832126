import 'swiper/css';
import 'swiper/css/effect-fade';

import travelPlanningMobile from 'assets/images/TRAVEL-PLANING-mobile.webp';
import travelPlanning from 'assets/images/TRAVEL-PLANING.webp';
import travelingMobile from 'assets/images/TRAVELING-mobile.webp';
import traveling from 'assets/images/TRAVELING.webp';
import { SearchMentor } from 'components/SearchMentor';
import { MEETING_TYPES } from 'constants/types';
import { useLanguage } from 'contexts/languageContext';
import { useSearch } from 'contexts/searchContext';
import { useEffect, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { Autoplay, EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ContentaButtonPrimary } from 'components/Styled/ContentaButton';
import { useNavigate } from 'react-router-dom';
import * as S from './styles';

function RandomTextWithFade() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const texts = [
    {
      title: t('traveler.home.randomTexts.title1'),
      text: t('traveler.home.randomTexts.text1'),
    },
    {
      title: t('traveler.home.randomTexts.title2'),
      text: t('traveler.home.randomTexts.text2'),
    },
    {
      title: t('traveler.home.randomTexts.title3'),
      text: t('traveler.home.randomTexts.text3'),
    },
    {
      title: t('traveler.home.randomTexts.title4'),
      text: t('traveler.home.randomTexts.text4'),
    },
    {
      title: t('traveler.home.randomTexts.title5'),
      text: t('traveler.home.randomTexts.text5'),
    },
    {
      title: t('traveler.home.randomTexts.title6'),
      text: t('traveler.home.randomTexts.text6'),
    },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showText, setShowText] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowText(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setShowText(true);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [texts]);

  return (
    <S.FadeTextContainer showText={showText}>
      <h1>{texts[currentIndex].title}</h1>
      <p>{texts[currentIndex].text}</p>
      <div className="about-us__container">
        <ContentaButtonPrimary
          className="about-us__button"
          onClick={() => navigate('/about-us')}
        >
          {t('footer.aboutUs')}
        </ContentaButtonPrimary>
      </div>
    </S.FadeTextContainer>
  );
}

export function HeaderHome() {
  const { handleRedirectWithFilters, handleSearchClick } = useSearch();
  const { currentLanguage } = useLanguage();
  const [languageChanged, setLanguageChanged] = useState(false);

  useEffect(() => {
    setLanguageChanged(true);
  }, [currentLanguage]);

  useEffect(() => {
    if (languageChanged) {
      setLanguageChanged(false);
    }
  }, [languageChanged]);

  return (
    <S.HeaderContent>
      <Swiper
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        loop={true}
        speed={2000}
        modules={[EffectFade, Autoplay]}
        effect="fade"
        fadeEffect={{
          crossFade: false,
        }}
      >
        <SwiperSlide className="home-slider__slide">
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={travelPlanning}
              alt="travel planning"
            />
            <img src={travelPlanningMobile} alt="travel planning" />
          </picture>
        </SwiperSlide>
        <SwiperSlide className="home-slider__slide">
          <picture>
            <source
              media="(min-width: 768px)"
              srcSet={traveling}
              alt="traveling"
            />
            <img src={travelingMobile} alt="traveling" srcSet="" />
          </picture>
        </SwiperSlide>
      </Swiper>
      <S.HeaderContainer>
        {!languageChanged && <RandomTextWithFade />}

        <SearchMentor
          handleFilterClick={handleRedirectWithFilters}
          handleSearchClick={handleSearchClick}
          initialValues={{
            travel_destination: '',
            name: '',
            scheduling_type: MEETING_TYPES.online,
          }}
        />
      </S.HeaderContainer>
    </S.HeaderContent>
  );
}
