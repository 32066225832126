import PropTypes from 'prop-types';

function FamilyTravelIcon({ color, height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={40}
      height={50}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox="917 1297 620 913"
    >
      <path
        d="M1147.94 1948.07c0 9.13-6.81 16.54-15.22 16.54-8.4 0-15.21-7.41-15.21-16.54v-317.25c0-9.13 6.81-16.53 15.21-16.53 8.41 0 15.22 7.4 15.22 16.53v317.25zm-106.15-501.33h91.87v-97.4c0-7.41 4.94-13.67 11.74-15.74 1.74-.63 3.62-.97 5.58-.97h177.05c6.17 0 11.56 3.42 14.4 8.49 1.42 2.42 2.22 5.22 2.22 8.22v97.4h91.88c23.02 0 43.94 9.4 59.08 24.55 15.15 15.15 24.56 36.07 24.56 59.08v504.61c0 23.02-9.41 43.93-24.56 59.08-15.14 15.15-36.06 24.56-59.08 24.56h-14.93v39.77c0 9.13-6.66 16.54-14.88 16.54-8.21 0-14.88-7.41-14.88-16.54v-39.77h-305.37v39.77c0 9.13-6.66 16.54-14.88 16.54-8.21 0-14.88-7.41-14.88-16.54v-39.77h-14.92c-23.02 0-43.94-9.41-59.09-24.56-15.15-15.15-24.55-36.06-24.55-59.08v-504.61c0-23.01 9.4-43.93 24.55-59.08 15.15-15.15 36.07-24.55 59.09-24.55zm125.13 0h144.48v-80.68h-144.48v80.68zm269.61 33.16h-91.88v43.05c0 9.09-7.44 16.46-16.62 16.46-9.19 0-16.63-7.37-16.63-16.46v-43.05h-144.48v43.05c0 9.09-7.45 16.46-16.63 16.46-9.19 0-16.63-7.37-16.63-16.46v-43.05h-91.87c-13.87 0-26.49 5.68-35.64 14.84-9.16 9.15-14.84 21.77-14.84 35.63v504.61c0 13.87 5.68 26.49 14.84 35.64 9.15 9.15 21.77 14.84 35.64 14.84h394.74c13.87 0 26.49-5.69 35.64-14.84 9.16-9.15 14.84-21.77 14.84-35.64v-504.61c0-13.86-5.68-26.48-14.84-35.63-9.15-9.16-21.77-14.84-35.64-14.84zm-75.72 468.17c0 9.13-6.82 16.54-15.22 16.54s-15.21-7.41-15.21-16.54v-317.25c0-9.13 6.81-16.53 15.21-16.53 8.4 0 15.22 7.4 15.22 16.53v317.25zm-106.44 0c0 9.13-6.81 16.54-15.21 16.54-8.4 0-15.21-7.41-15.21-16.54v-317.25c0-9.13 6.81-16.53 15.21-16.53 8.4 0 15.21 7.4 15.21 16.53v317.25z"
        style={{
          fill: '#8d8b8d',
          fillRule: 'nonzero',
        }}
      />
    </svg>
  );
}

FamilyTravelIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

FamilyTravelIcon.defaultProps = {
  width: 150,
  height: 150,
  color: '#868484',
};

export default FamilyTravelIcon;
