export default {
  translations: {
    common: {
      addPhoto: 'Añadir foto',
      address: 'Dirección',
      answer: 'Responder',
      back: 'Regresar',
      betaVersion: 'versión beta',
      cancel: 'Cancelar',
      close: 'Cerrar',
      confirm: 'Confirmar',
      contentaPhraseLogo: '_ el mundo es tuyo, viaja sin límites',
      continue: 'Continuar',
      create: 'Crear',
      de: 'de',
      delete: 'Eliminar',
      edit: 'Editar',
      find: 'Búsqueda',
      finish: 'Finalizar',
      hello: 'Hola',
      hour: 'Hora',
      hours: 'Horas',
      idioms: 'Idiomas',
      inPerson: 'En persona',
      loading: 'Cargando',
      logout: 'Cerrar sesión',
      refuseds: 'Rechazados',
      register: 'Registrar',
      remove: 'Eliminar',
      requestChange: 'Solicitar cambio',
      reschedule: 'Reagendar',
      save: 'Guardar',
      saving: 'Guardando',
      selfRegister: 'Registrarse',
      send: 'Enviar',
      success: 'Éxito',
      successUpdate: 'Actualización exitosa',
      underConstruction: 'En construcción',
      underConstructionSubTitle: 'Estamos trabajando en ello',
      noAvailableSchedules: 'No hay horarios disponibles',
      update: 'Actualizar',
      upload: 'Subir',
      tryAgain: 'Intentar de nuevo',
      virtual: 'Virtual',
      traveller: 'Viajero',
      advisor: 'Advisor',
      didntShowUp: 'no se presentó',
      you: 'Tú',
      traveler: 'Traveler',
      advisorPhrase: 'Frase del Advisor',
      languages: {
        'pt-BR': 'Portugués',
        'en-US': 'Inglés',
        es: 'Español',
      },
      error: {
        generic: 'Ocurrió un error',
        errorFetching: 'Error al buscar datos',
        errorFetchingFutureSchedules: 'No se pudo cargar los horarios futuros',
        errorFetchingPasSchedulest: 'No se pudo cargar los horarios pasados',
        errorFetchingAdvisors: 'No se pudo cargar los Advisors',
        createSchedule: 'No se pudo crear el agendamiento',
        newsletter: 'No se pudo suscribirse a la newsletter',
        under18: 'Debes ser mayor de 18 años',
        rescheduleMeeting: 'No se pudo reagendar la reunión',
        submitResume: 'No se pudo enviar el resumen',
      },
      successMessages: {
        createSchedule: 'Agendamiento creado con éxito',
        submitResume: 'Resumen enviado con éxito',
      },
    },
    home: {
      title: '¿Quieres recibir los mejores consejos de viaje?',
      subtitle: 'Encuentre guías turísticos expertos en su estilo de viaje.',
      firstInput: {
        label: '¿Dónde?',
        placeholder: '¿Cuáles son tus planes?',
      },
      secondInput: {
        label: '¿Qué experiencias estás buscando?',
        placeholder: '¿Cuáles son tus planes?',
      },
      giftText: 'Regala a alguien especial una experiencia increíble',
      giftButton: 'Regalar cupón',
      notTalked: '¿Aún no has hablado con nuestros guías?',
      letsStart: 'Empezamos creando tu primer horario.',
      newSchedulle: 'Nuevo horario',
      banner: {
        contenta: '_ Contenta 360º',
        paragraph:
          '<p>Conéctate con nuestros Travel Experts que viven en el destino que quieres visitar. Nuestros Travel Experts son guías turísticos locales y expertos profesionales.</p><p>Están listos para ayudarte en todas las etapas de la planificación de tu viaje; desde reuniones virtuales de pre-planificación hasta visitas en persona.</p>',
        buttonKnow: 'Conoce más',
        title:
          '¿Eres un <strong>Guia de turismo</strong> y te gustaría ser un <strong>Travel Expert</strong> de Contenta 360º?',
        beAdvisor: 'Ser un Travel Expert',
      },
      countryNotInformed: 'País no informado',
    },
    CSV: {
      checkData: 'Comprobar los datos',
      advisorName: 'Nombre',
      advisorSurname: 'Apellido',
      advisorEmail: 'Email',
      advisorNationality: 'Nacionalidad',
      advisorSummary: 'Resumen',
      advisorBirthDate: 'Fecha de nacimiento',
      advisorMobilePhone: 'Teléfono Celular',
      advisorPhone: 'Teléfono',
      languageName: 'Idioma',
      customLanguageLanguage: 'Otro idioma',
      consultingRegionName: 'Región',
      consultingRegionCountry: 'País',
      consultingRegionState: 'Estado',
      consultingRegionCity: 'Ciudad',
      consultingRegionDetails: 'Detalle',
      companyName: 'Nombre de la Empresa',
      companyDocument: 'Documento de la Empresa',
      companyPosition: 'Su puesto en la empresa',
      classAssociationName: 'Nombre del organismo regulador o asociación',
      classAssociationRegisterNumber:
        'Número de registro del organismo regulador o de la asociación',
      classAssociationAssociatedSince: 'Asociado desde',
      addressCountry: 'País',
      addressState: 'Estado',
      addressCity: 'Ciudad',
      addressStreet: 'Dirección',
      addressZipcode: 'Código postal',
      addressComplement: 'Complemento',
      specialties: 'Especialidades',
      customSpecialty: 'Otras especialidades',
      createdAt: 'Creado en',
      updatedAt: 'Actualizado en',
      currency: 'Moneda',
      valueHour: 'Precio por hora trabajada',
    },
    guides: {
      // checkData: 'Verifique os dados antes de continuar',
      checkData: 'Verifique los datos antes de continuar',
      socialName: 'Nombre social',
      register: 'Registrar',
      alreadyAccount: '¿Ya tienes una cuenta?',
      enterHere: 'Ingresa aquí',
      step: 'Paso',
      registerSuccess1: 'Gracias por registrarte en Contenta360.',
      registerSuccess2:
        'Tu cuenta de Travel Expert estará disponible después de una entrevista con Contenta360',
      registerSuccess3: 'Agenda tu entrevista ',
      clickHere: 'haciendo clic aquí',
      registerSuccess:
        '¡Gracias por registrarte! Hablaremos con usted en breve.',
      name: 'Nombre',
      nameRequired: 'Campo requerido',
      surname: 'Apellido',
      surnameRequired: 'Campo requerido',
      birthday: 'Nascimento',
      day: 'Día',
      dayRequired: 'Campo requerido',
      month: 'Mes',
      monthRequired: 'Campo requerido',
      year: 'Año',
      yearRequired: 'Campo requerido',
      ddi: 'DDI',
      ddiRequired: 'Campo requerido',
      mobilePhone: 'Célula',
      mobilePhoneRequired: 'Campo requerido',
      nationality: 'País de nacimiento',
      nationalityRequired: 'Campo requerido',
      residenceCountry: 'País de residencia',
      residenceCountryRequired: 'Campo requerido',
      zipcode: 'Código postal',
      zipcodeRequired: 'Campo requerido',
      state: 'Estado',
      stateRequired: 'Campo requerido',
      city: 'Ciudad',
      cityRequired: 'Código postal',
      street: 'Direción 1',
      streetRequired: 'Campo requerido',
      complement: 'Direción 2',
      number: 'Número',
      neighborhood: 'Barrio',
      neighborhoodRequired: 'Campo requerido',
      specialties: '¿Cuáles son tus especialidades?',
      chooseUpToFiveOptions: 'Elige hasta 5 opciones',
      chooseUpToFiveOptionsLimit: 'No más de 5 opciones',
      chooseUpToFiveOptionsRequired: 'Campo Requerido',
      customSpecialty: 'Otras',
      tellAboutYourHistory:
        'Cuenta un poco de tu história, muestra tu pasión por lo que haces aquí.',
      tellAboutYourHistoryDesc:
        'En formato de texto, y en los idiomas que hablas, cuéntanos un poco sobre ti. Por ejemplo: su tiempo de experiencia, sus especialidades en el destino, tiempo de formación, la cantidad de clientes atendidos. Muestra tu pasión por lo que haces.',
      tellAboutYourHistoryRequired: 'Campo requerido',
      tellAboutYourHistoryCharLimit: 'No más de 2500 caracteres',
      association: 'Órgano emisor del registro',
      associationRequired: 'Orgão Emissor do Registro é obrigatório',
      registerNumber: 'Número de registro',
      registerNumberRequired: 'Campo requerido',
      associatedDay: 'Asociados desde',
      associatedDayRequired: 'Campo requerido',
      valueHour: 'Importe Cobrado por hora de consulta',
      valueHourRequired: 'Campo requerido',
      videoCallPrice: 'Llamada de video',
      presentialTour: 'recorrido cara a cara',
      internationalPurchase: 'Compra internacional',
      packageValue: 'Valor del paquete',
      investmentValue: 'Valor de inversión',
      currency: 'Moneda',
      currencyPlaceholder: 'Elegir moneda',
      currencyRequired: 'Moeda é obrigatória',
      companyName: 'Nombre de la empresa',
      companyNameRequired: 'Campo requerido',
      companyDocument: 'número de identificación fiscal (NIF) ',
      companyDocumentRequired: 'Campo requerido',
      companyRole: 'Puesto',
      companyRoleRequired: 'Campo requerido',
      numberRequired: 'El numero es requerido',
      registerTuristGuide:
        'Registro de Guía Turístico y/o Registro en la Asociación Profesional',
      isRequiredLicense: '¿Es necesario tener licencia de guía de turismo?',
      chooseAOption: 'Escoge una opción',
      chooseAOptionRequired: 'Campo requerido',
      yes: 'Sí',
      no: 'No',
      depends: 'Depende',
      monthJanuary: 'Enero',
      monthFebruary: 'Febrero',
      monthMarch: 'Marzo',
      monthApril: 'Abril',
      monthMay: 'Mayo',
      monthJune: 'Junio',
      monthJuly: 'Julio',
      monthAugust: 'Agosto',
      monthSeptember: 'Septiembre',
      monthOctober: 'Octubre',
      monthNovember: 'Noviembre',
      monthDecember: 'Diciembre',
      country: 'Pais',
      countryRequired: 'Campo requirido',
      aboutDestination: 'Sobre el destino donde trabajas.',
      consultancyDetails:
        'Detalla un poco más sobre tu región de actividad y área de conocimiento',
      languages: 'Idiomas',
      languageRequired: 'Idioma é obrigatório',
      otherLanguages: 'Otras',
      photo: 'Foto',
      photoIsRequired: 'Foto es requerida',
      email: 'E-mail',
      mustBeAdult: 'Debes ser mayor de edad para registrarte',
      invalidDate: 'Fecha inválida',
      registerNumberDetails: 'Por favor, detalle',
      registerNumberDetailsRequired: 'Campo requerido',
      profilePhrase:
        'Cuéntanos en una frase por qué eres apasionado por tu profesión. Esta será la frase de tu perfil en la página de inicio de Contenta360.',
      profilePhraseRequired: 'Campo requerido',
      zipcodeError: 'No se pudo encontrar el CEP',
    },
    languages: {
      german: 'Alemán',
      english: 'Inglés',
      korean: 'Coreano',
      spanish: 'Español',
      french: 'Francés',
      italian: 'Italiano',
      japanese: 'Japonés',
      portuguese: 'Portugués (Portugal)',
      portugueseBrazil: 'Portugués (Brasil)',
      code: {
        DE: 'Alemán',
        IT: 'Italiano',
        KO: 'Coreano',
        JA: 'Japonés',
        ES: 'Español',
        'PT-BR': 'Portugués (Brasil)',
        FR: 'Francés',
        'PT-PT': 'Portugués (Portugal)',
        EN: 'Inglés',
        en_US: 'Inglés',
        'en-US': 'Inglés',
        en: 'Inglés',
      },
    },
    specialties: {
      notFound: 'No se encontraron especialidades',
      beach: 'Playa',
      mountain: 'Montaña',
      urban: 'Urbano',
      cruise: 'Crucero',
      resort: 'Resort',
      gastronomy: 'Gastronomía',
      wine: 'Vino',
      adventure: 'Aventura',
      ecotourism: 'Ecoturismo',
      shopping: 'Compras',
      culture: 'Cultura',
      adrenaline: 'Adrenalina',
      sports: 'Deportes',
      'Natureza & Aventura': 'Natureza y Aventura',
      'Urbano - Lifestyle': 'Urbano - Lifestyle',
      'Vinho, Azeite & Gastronomia': 'Vino, Aceite y Gastronomía',
      'Aventuras & Esportes': 'Aventuras y Deportes',
      'História, Arte & Cultura': 'Historia, Arte y Cultura',
      'Espiritualidade e Religião': 'Espiritualidad y Religión',
      'Turismo Acessível & Necessidades Especiais':
        'Turismo Accesible y Necesidades Especiales',
      'Viajar em Família ': 'Viajar en Familia',
      'Saúde & Bem Estar': 'Salud y Bienestar',
      'Viagem Econômica - Super Dicas': 'Viaje Económico - Super Consejos',
      'Turismo LGBTQIA+': 'Turismo LGBTQIA+',
      'Viagens de Luxo': 'Viajes de Lujo',
      Afroturismo: 'Afroturismo',
      'Comida e Gastronomia': 'Comida y Gastronomía',
    },
    months: {
      January: 'Enero',
      February: 'Febrero',
      March: 'Marzo',
      April: 'Abril',
      May: 'Mayo',
      June: 'Junio',
      July: 'Julio',
      August: 'Agosto',
      September: 'Septiembre',
      October: 'Octubre',
      November: 'Noviembre',
      December: 'Diciembre',
      '01': 'Enero',
      '02': 'Febrero',
      '03': 'Marzo',
      '04': 'Abril',
      '05': 'Mayo',
      '06': 'Junio',
      '07': 'Julio',
      '08': 'Agosto',
      '09': 'Septiembre',
      10: 'Octubre',
      11: 'Noviembre',
      12: 'Diciembre',
    },
    errors: {
      errorSizeUpload: 'La imagen debe ser menor que 2MB.',
      errorTypeUpload: 'El archivo debe ser en formato jpg, jpeg o png.',
    },

    breadcrumb: {
      calls: 'Llamadas',
      history: 'Historico',
      home: 'Inicio',
      profile: 'Perfil',
      advisors: 'Advisors',
      scheduledTours: 'Tours agendados',
      pendingBriefings: 'Briefings pendientes',
      formBriefing: 'Formulario de Briefing',
    },

    footer: {
      privacy: 'Privacidad',
      terms: 'Términos',
      legal: 'Legal',
      faq: 'FAQ',
      termsConditions: 'Términos y Condiciones',
      subscribeSuccess: '¡Gracias por suscribirte!',
      privacyPolicy: 'Política de Privacidad',
      aboutUs: 'Sobre nosotros',
      newsletter: 'Suscríbete a nuestro boletín:',
      subscribe: 'Suscribirse',
      subscribePlaceholder: 'Ingresa tu correo electrónico',
    },
    weekdays: {
      0: 'Domingo',
      1: 'Lunes',
      2: 'Martes',
      3: 'Miércoles',
      4: 'Jueves',
      5: 'Viernes',
      6: 'Sábado',
    },
    sidebar: {
      schedule: 'Horario',
      home: 'Inicio',
      history: 'Historico',
      findGuide: 'Encuentra un guía',
      account: 'Cuenta',
      notifications: 'Notificaciones',
      messages: 'Mensajes',
      settings: 'Configuración',
      profile: 'Perfil',
    },

    mentorList: {
      title: 'Guías',
      filterByStatus: 'Filtrar por estado',
      searchPlaceholder: 'Buscar por nombre',
      view: 'Ver aprobad@s',
      download: 'Descargar CSV',
      suspend: 'Suspender seleccionados',
      showing: 'Mostrando',
      mentors: 'Guías',

      table: {
        name: 'Nombre',
        country: 'País',
        subscriptionDate: 'Fecha de suscripción',
        status: 'Status',
      },
    },

    agenda: {
      available: 'Horarios disponibles',
      online: 'Reunión Online',
      inPerson: 'Reunión Presencial',
      noEvents: 'No hay eventos disponibles',
      title: 'Agenda',
      nextEvents: 'Próximos eventos',

      travellerCancelModal: {
        title: 'Cancelar reunión',
        paragraph1: 'Tienes certeza que deseas cancelar esta reunión?',
        paragraph2:
          'Al cancelar la reunión, perderás la información completada en el Briefing.',
        paragraph3:
          'Tienes certeza que deseas cancelar esta reunión? Faltan menos de 48 horas para la realización de la reunión.',
        paragraph4:
          'En este caso, como se informó en el momento de la contratación del servicio, se aplica una multa del 100% del valor original, es decir, no se reembolsará ningún valor.',
        paragraph5: '¿Deseas cancelar la reunión?',
        paragraph6:
          'Tienes certeza que deseas cancelar esta reunión? Faltan menos de 72 horas para la realización de la reunión presencial.',
        paragraph7:
          'En este caso, como se informó en el momento de la contratación del servicio, se aplica una multa del 100% del valor original, es decir, no se reembolsará ningún valor.',
        paragraph8: '¿Deseas cancelar la reunión?',
      },

      rescheduleModal: {
        title: 'Reagendar reunión',
        paragraph1: '¿Estás seguro de que deseas reprogramar esta reunión?',
        paragraph2:
          'Faltan menos de 48 horas para la realización de la reunión.',
        paragraph3:
          'En este caso, como se informó en el momento de la contratación del servicio, se aplica una multa del 100% del valor original, es decir, no se reembolsará ningún valor.',
        button: 'Reagendar reunión',
        rescheduleButton: 'Confirmar reprogramación',

        advisorText: `<p>En la plataforma, en este momento el asesor de viajes no puede realizar cambios de horario.</p>

        <p>Al confirmar, <strong>el sistema solicitará al viajero TRAVELLER_NAME que seleccione una nueva fecha y hora de su agenda.</strong></p>

        <p>Por favor, verifique los horarios disponibles para evitar tener que solicitar un nuevo cambio de horario.</p>

        <p>Aunque podamos ofrecer otras opciones de horarios al viajero, el único horario disponible podría ser el que seleccionó anteriormente.</p>

        <p>En este caso, también permitiremos que el cliente cancele la reunión de forma gratuita y busque un nuevo asesor de viajes.</p>

        <p>¿Desea continuar con la solicitud de cambio de horario?</p>

        <p>Recuerde: Una alta tasa de cambios de horario <strong>impacta negativamente en su evaluación como asesor de viajes</strong>, y en consecuencia en su posición en el ranking de búsqueda de la plataforma.</p>
        `,

        cancelSolicitation: 'Cancelar solicitud',
        agree: 'Sí, solicitar reprogramación',
        error: 'Hubo un error al solicitar el cambio de horario.',
      },

      cancelModal: {
        title: 'Cancelar cita',
        paragraph1: '¿Estás seguro de que deseas cancelar esta cita?',
        paragraph2:
          'El alto índice de cancelaciones <strong>impacta negativamente en su evaluación como Travel Expert en Contenta360</strong>, y por lo tanto en su posición de recomendación en el ranking de búsqueda de la plataforma.',
        paragraph3:
          'Recomendamos que organice su agenda, bloqueando los horarios en los que estará disponible para no tener que cancelar si un cliente elige una hora inconveniente.',
        button: 'Cancelar cita',
        success: 'Cita cancelada con éxito!',
        error: 'Hubo un error al cancelar la cita.',
      },

      scheduleModal: {
        canceledByTraveler: 'Cancelada por el Traveler',
        canceledByAdvisor: 'Cancelada por el advisor',
        canceledByAdministrator: 'Cancelada por el administrador',
        rescheduled: 'Reagendada',
        canceled: 'Cancelada',
      },
    },

    withoutYCBM: {
      title: 'Regístrate en nuestro socio para administrar tu agenda.',
      content: {
        title: 'Conoce a nuestro socio',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer placerat viverra risus ac finibus. Donec nec metus massa. Vestibulum faucibus tellus arcu, sit amet imperdiet velit ultricies sit amet. Nam mattis enim non purus laoreet, eu finibus lacus tincidunt. Integer vel risus rhoncus, faucibus arcu id, luctus nulla.',
        boldDescription:
          'Regístrate utilizando el mismo correo electrónico que se registró en Contenta360',
      },
      headerMessage: {
        button: 'Editar agenda',
        title: 'Configura tu agenda en nuestro socio.',
      },
    },

    buttons: {
      accept: 'Aceptar',
      enterCall: 'Entrar en la llamada',
      briefing: 'Briefing',
      seeBriefing: 'Ver Briefing',
      seeResume: 'Ver resumen',
      reprove: 'Reprobar',
      suspend: 'Suspender',
      approve: 'Aprobar',
      bookNow: 'Reservar',
      schedule: 'Agendar',
      seeMore: 'Ver más',
      advisor: 'Advisor',
      beAdvisor: 'Conviértete en un Travel Expert',
      enter: 'Ingresar',
      evaluate: 'Evaluar',
      cancel: 'Cancelar',
      resume: 'Resumo',
      finishBeAdvisor: 'Finalizar cadastro de Advisor',
      finishBeTraveler: 'Finalizar cadastro de viajante',
      willBeEnabled:
        'El botón estará habilitado 1 hora antes del inicio de la reunión',
    },

    statusCode: {
      approved: 'Aprobado',

      pending: 'Pendiente',
      disapproved: 'Reprobado',
      suspended: 'Suspendido',
    },

    mentorDetails: {
      headers: {
        title: 'Detalles del mentor',
        contactInfo: 'Información de contacto',
        guideInfo: 'Registro profesional',
        companyInfo: 'Representa empresa en el sector de turismo',
        destinyInfo: 'Sobre el destino en el que actúa',
        specialtiesInfo: 'Especialidades',
      },

      contactFields: {
        status: 'Status',
        birthday: 'Fecha de nacimiento',
        countryBirth: 'País de nacimiento',
        creationAccountDate: 'Fecha de creación de la cuenta',
        mobilePhone: 'Celular',
        email: 'E-mail',
        countryResidence: 'País de residencia',
        stateResidence: 'Estado',
        cityResidence: 'Ciudad',
        address: 'Dirección',
        zipCode: 'CEP',
        guideStory: 'Historia del guía',
      },

      guideFields: {
        emitter: 'Órgano emisor',
        registration: 'Número de registro',
        associatedSince: 'Asociado desde',
        valueHour: 'Valor/hora',

        currency: 'Moneda',

        city: 'Ciudad',
      },

      companyFields: {
        name: 'Nombre de la empresa',
        cnpj: 'CNPJ',
        role: 'Cargo',
      },

      destinyFields: {
        country: 'País',
        state: 'Estado',
        city: 'Ciudad',
        regionDetails: 'Detalles de la región',
        languages: 'Idiomas',
      },

      specialtiesFields: {
        options: 'Opciones',
        others: 'Otras',
      },
    },

    tables: {
      tooltipTitle: 'Haga clic para ordenar',
    },

    filters: {
      title: 'Filtros activos',
      name: 'Nombre',
      status: 'Status',
    },

    mentorProfile: {
      contact: 'Contacto',
      profile: 'Perfil',
      personalData: 'Datos personales',
      professional: 'Profesional',
      destiny: 'Destino de actuación',
      idioms: 'Idiomas',
      membership: 'Membresía',
      video: 'Video',
      videoMessage:
        'El video debe tener formato <b>.mp4</b> y un peso máximo de <b>200MB</b>.',
      specialties: 'Especialidades',
      financial: 'Financiero',
      residence: 'Residencia',
      profilePhrase: 'Frase de perfil',
      addNewCertificate: 'Agregar nueva certificación',
      guideLicense:
        'Licencia Guía de Turismo - Licencia o Registro Profesional',
      noPhrase: 'Este guía no tiene una frase de perfil todavía',
      photoMessage:
        'La imagen debe tener formato <b>.jpeg</b> o <b>.png</b> y un peso máximo de <b>2MB</b>.',
      uploadingPhoto: 'Subiendo foto...',
      guideLocation: {
        title: 'Localidade em que você atua',
      },
      guideIdioms: {
        title: 'Idiomas que tem fluência',
        otherLanguages: 'Outros',
      },
      guideSpecialties: {
        change: '¿Desea cambiar sus especialidades?',
        tellABitLabel: 'En formato de texto, y en los idiomas que habla',
      },
      financialData: {
        title: 'Valor/hora',
        bankTitle: 'Datos bancarios',
        bankSubtitle: 'Donde recibirá por sus servicios internacionales.',
        bankBrazilAccount:
          '¿Tiene una cuenta bancaria en Brasil? Informe a continuación.',
        onlineConsultation: 'Encontro on-line - Reunião virtual',
        valueHourRequired: 'Valor/hora es obligatorio',
        presencialTour: 'Tour Guiado Presencial',
        presentialConsultation:
          'Encontro Presencial - Tour Guiado (máximo 06 personas)',
        valueHour: 'Valor/hora',
        valueHour2person: 'Valor hasta 2 personas',
        valueHour3person: 'Valor até 3 pessoas',
        valueHour4person: 'Valor até 4 personas',
        valueHour6person: 'Valor até 6 personas',
        valueFourHours: 'Valor hasta 4 horas',
        valueSixHours: 'Valor hasta 6 horas',
        valueEightHours: 'Valor hasta 8 horas',
        valueTenHours: 'Valor hasta 10 horas',
        bankAccount: {
          bankNumber: 'Número del banco',
          bankName: 'Nombre del banco',
          agencyNumber: 'Número de la agencia',
          accountNumber: 'Número de la cuenta',
          swiftCode: 'Código SWIFT',

          ibanCode: 'Código IBAN',
          bank: 'Banco',
          agency: 'Agencia',
          account: 'Cuenta',
          digit: 'Dígito',
          bankRequired: 'Banco es obligatorio',
          agencyRequired: 'Agencia es obligatorio',
          accountRequired: 'Cuenta es obligatorio',
          digitRequired: 'Dígito es obligatorio',
        },
        stripeTitle: 'Número cuenta Stripe',
        stripeSubtitle: '¿Tiene una cuenta en nuestro socio Stripe?',
        stripeAccount: 'Cuenta Stripe',
        rule1: 'Usted es responsable de pagar impuestos sobre sus ingresos.',
        rule2:
          'El valor del tour guiado presencial debe informarse considerando todos los costos del guía de turismo (Ejemplo: transporte ida y vuelta + comida).',
        rule3: 'No habrá pago adicional por parte del cliente.',
        rule4:
          'Los tours guiados presenciales complejos, con costos adicionales a la hora del guía de turismo, no pueden venderse a través de Contenta360, en esta primera fase de la plataforma',
      },
      videoData: {
        uploadText: 'Arraste y suelte un video aquí o ',
        uploadLink: 'haga un upload',
        rules: {
          time: 'El video de presentación debe tener hasta 1:30min',
        },
        // success: 'Video uploaded successfully',
        success: 'Video enviado con éxito',
        error: 'Error al enviar el video',
      },

      membershipData: {
        title: 'Su membresía',
        cards: 'Tarjetas registradas',
        currencyReceived: 'Moneda para recibir pagos',
        accountStatus: 'Estado de la cuenta',
        acountStart: 'Iniciada en',
        usedCard: 'Tarjeta que se está utilizando',
        nextExpiration: 'Próxima renovación',
        cancelMembership: 'Cancelar membresía',
        changeCard: 'Cambiar tarjeta',
        deactivateMembership: 'Desactivar membresía',
        reactivateMembership: 'Reactivar membresía',
        activateMembership: 'Activar membresía',

        cancelMembershipReason:
          'Por favor, describa el motivo de la cancelación',
        selectOption: 'Seleccione una opción',
        options: {
          noMoney:
            'No tengo condiciones de pagar la membresía de la plataforma en este momento.',
          noClients: 'No he conseguido clientes.',
          noLike: 'No me gustó la plataforma.',
          impediment: 'Estoy impedido por motivos de salud.',
          goActOtherSegment: 'Voy a actuar en otro segmento.',
        },
        other: 'Se desejar, faça observações abaixo',
        actual: 'Actual',
        inactive: 'Inactiva',
        canceled: 'Cancelada',
        active: 'Activa',
        trial: 'Prueba',
        table: {
          data: 'Data',
          card: 'Tarjeta',
          value: 'Valor',
          status: 'Status',
          actions: 'Acciones',
        },
      },

      whyAmI: 'Porque soy un Advisor',
      whyAmIQuestion: '¿Por qué soy un Advisor?',
      experience: 'Experiencias',
      rating: 'Avaliaciones',
      investmentValue: 'Valor/hora',

      licensedGuide: 'Licenciado',

      publications: {
        title: 'Sus publicaciones',
      },

      valueHour: {
        presentialTour: 'Tour presencial',
        onlineConsultation: 'Encontro on-line - Reunion virtual',
        hour: 'hora',
        untilFourHour: 'hasta 4 horas',
      },

      schedule: {
        title: 'Agende su mentoría turística ahora',
        videoCall: 'Video llamada',
        presential: 'Presencial',
      },
    },

    briefing: {
      hour: 'Horario',
      title: 'Briefing',
      scheduled: 'Agendado para',
      localTime: 'Horario local del viajero',
      chargedValue: 'Valor cobrado',
      scheduledFor: 'Agendamento para',
      travellerInterests: {
        title: 'Intereses generales del viajero',
      },
      remainingTime: 'Tiempo restante para realizar el pago:',
      form: {
        success: 'Briefing enviado con éxito',
      },
      actions: {
        goHome: 'Ir para a home',
      },
    },

    mentorHome: {
      title: '¡Usted con autoridad, proyección internacional y rentabilidad!',
      fullAgenda: 'Agenda completa',
      seeCompleteAgenda: 'Ver mi agenda completa',
      noScheduleTitle: 'Aún no tiene ninguna conversación agendada.',
      noScheduleParagraph:
        'Contenta 360º presenta su perfil de forma inteligente para los viajeros con interés en su localidad y especialidades.',
      noScheduleParagraph2:
        'Mantenga su perfil actualizado, ejerza la colaboración, envíe sus videos para impulsar la búsqueda por su ciudad y región.',
      nextScheduleTitle: 'Mis próximas conversaciones',
      acceptPenalty: 'Aceptar penalidad',
      giveUpCancel: 'Desistir del cancelamiento',
      cancelRequest: 'Pedido de Cancelamiento',
      subscribeNow: 'Assine agora',
      notMember: {
        title: 'Ops! Su perfil aún no ha sido aprobado',
        paragraph1:
          'Hola, la plataforma Contenta360 hace una selección de los proveedores de servicios, con el objetivo de garantizar la calidad de los servicios y la seguridad',
        paragraph2:
          'Ya estás registrado, espera el contacto de nuestro equipo. Si aún no ha programado su entrevista, prográmela en este enlace: [LINK]',
        paragraph3:
          'Después de la entrevista, recibirá un correo electrónico con la respuesta sobre su evaluación.',
        doubts: 'Si tiene alguna pregunta, contáctenos por correo electrónico ',
      },
    },

    modal: {
      attention: 'Atención',
      clickRead: 'Haga clic aquí y consulte el reglamento',
      cancelSchedule: {
        paragraph1:
          'Cancelar o reagendar una video llamada con antelación menor que 24 horas, puede generar',
        penaltyAmount: 'penalidad de 20%',
        paragraph2: 'sobre el valor total pagado por el cliente.',
      },

      evaluate: {
        title: 'Evaluar',
        subtitle: 'Tu evaluación es muy importante para nosotros!',
        traveler: 'Viajero',
        advisor: 'Advisor',
        successMessage: 'Evaluación realizada con éxito',
        rateQuestions: {
          interaction: 'Interacción y conexión con usted',
          content: 'Calidad del contenido del Travel Expert',
          qualityAudio: 'Calidad de audio y video',
          punctuality: 'Puntualidad',
          behavior: 'Comportamiento en la sala',
          otherComments: 'Si lo desea, agregue observaciones a su evaluación.',
        },
      },
    },

    routes: {
      home: 'home',
      profile: 'perfil',
      mentor: 'mentor',
      travellerRegister: 'registro/viajero',
    },

    register: {
      CPF: 'CPF',
      CPFRequired: 'CPF es obligatorio',
      gender: 'Género',
      genderRequired: 'Género es obligatorio',
      masculine: 'Masculino',
      feminine: 'Femenino',
      nonBinary: 'No binario',
      preferNotToSay: 'Prefiero no decir',
      personLGBTQIA: '¿Se identifica como persona LGBTQIA+?',
      personLGBTQIARequired: 'Campo obligatorio',
      yes: 'Si',
      no: 'No',
      civilStatus: 'Estado civil',
      civilStatusRequired: 'Estado civil es obligatorio',
      single: 'Soltero(a)',
      married: 'Casado(a)',
      divorced: 'Divorciado(a)',
      widower: 'Viudo(a)',
      separated: 'Separado(a)',
      partner: 'Compañero(a)',
      completeRegister: 'Registro',
      successRegistered: 'Confirme su correo electrónico',
      successRegisteredMessage:
        'Enviamos un mensaje de confirmación a su correo electrónico. Confirme el recibo.',
      others: 'Otros',
      success: '¡Registro realizado con éxito!',
      finishProfile: '¡Finalizar perfil!',
    },

    sideProfile: {
      updatePhoto: 'Actualizar foto',
      accountStatus: 'Estado de la cuenta',
      professionalProfile: 'Perfil profesional en redes sociales',
      mobilePhone: 'Celular',
      accountStatusLabel: {
        notPaid: 'No pagado',
        pendingPayment: 'Pago pendiente',
        notApproved: 'No aprobado',
        approved: 'Aprobado',
        blocked: 'Bloqueado',
        // pending: 'Em análise',
        pending: 'En análisis',
      },
    },

    findAdvisor: {
      scheduleModal: {
        title: 'Reserve ahora',
        amount: 'Seleccione la duración del tour:',
        valueEightHours: '8 horas',
        valueFourHours: '4 horas',
        valueSixHours: '6 horas',
        valueTenHours: '10 horas',
        noPresential: 'Este Advisor no ofrece tours presenciales',
        participants: 'Participantes (máximo 6 personas permitidas):',
        notEnoughSlots: 'No hay suficientes espacios disponibles',
      },
    },

    history: {
      searchBar: {
        specificDestiny: 'Buscar citas específicas',
        specificDestinyPlaceholder:
          'Use el nombre del Advisor o el destino del viaje',
        specificDestinyAdvisor:
          'Use el nombre del Advisor o el destino del viaje',
        showAppointments: 'Mostrar citas',
        past: 'Pasado',
        future: 'Futuro',
      },
    },

    traveler: {
      giftCardModal: {
        title: 'Tarjeta de regalo',
        buttonPayment: 'Ir para el pago',
        buttonSchedule: 'Ir para agendamiento',
        name: 'Nombre del presenteado',
        email: 'Email del presenteado',
        message: 'Enviar un mensaje',
        giftCardData: 'Datos del presenteado',
        scheduleData: 'Agendamiento',
        desiredValue: 'Valor del regalo',
        sendDate: 'Fecha de envío de la tarjeta de regalo',
        wantChooseAdvisor: 'Quiero elegir al Travel Expert',
        wantUserChoose: 'Quiero que ${USER} elija',
      },

      home: {
        title:
          'Planea tu viaje de <br /> forma segura, rápida y con <br />información personalizada <br />para tus necesidades',
        paragraph1:
          'Nuestros especialistas, que viven en el destino turístico que visitará, son las personas que viven de cerca todo lo que necesita saber. Tienen autoridad para ayudarlo en esta fase de su viaje.',
        paragraph2:
          'Encuentre aquí al Mentor o Mentora que tiene la cara de su próxima aventura, bienvenido a nuestro Foro! Nuestra sala de visitas es virtual, pero llena de calor humano.',
        myTravelDestiny: 'Destino',
        preferredDestinyPlaceholder: 'Busque por la localidad',
        preferredMentor: 'Travel Expert',
        virtual: 'Reunión on-line',
        knowTravelAdvisor: 'Ya sé con quién quiero hablar',
        preferredMentorPlaceholder: 'Busque por el nombre',
        meetingMentor: 'El encuentro con mi Travel Expert será',
        notTalkedMentor: '¿Todavía no ha hablado con nuestros Mentores?',
        meetMentors: 'Conozca a algunos de nuestros Travel Experts',
        findSpecialtyPlaceholder: 'Busque por la especialidad del Mentor',
        findSpecialty: 'Buscar por especialidad del Travel Expert',
        specialtiesModalTitle: 'Especialidades',
        specialtiesModalButton: 'Buscar especialidades',
      },

      scheduleBriefing: {
        invalidCardName: 'Nombre de tarjeta inválido',
        saveCreditCard: 'Guardar tarjeta de crédito',
        dateRequired: 'La fecha es obligatoria',
        title: 'Briefing de Agendamiento',
        paymentTitle: 'Pago',
        investmentValue: 'Valor de la inversión',
        paymentDetailsSubtitle: 'Detalle de la compra',
        paymentAddress: 'Dirección de cobro',
        paymentYourAddress: 'Su dirección',
        paymentDetails: 'Detalles del pago',
        paragraph1:
          'Para proporcionar una mejor aprovechamiento del tiempo de su video llamada con el Advisor, preparamos un breve cuestionario sobre su perfil, favor responder con la mayor claridad posible.',
        paragraph2: 'Estas informaciones serán compartidas con su Advisor.',
        youWillTravel: '¿Viajará?',
        withFamily: 'Con la familia',
        withFriends: 'Grupo de amigos',
        withPartner: 'Pareja',
        withAlone: 'Solo',
        expectedDateTitle: '¿Ya tiene una fecha probable para viajar?',
        hasKids: 'Con niños menores de 15 años?',
        ageRange1: '0 a 2 años',
        ageRange2: '3 a 5 años',
        ageRange3: '6 a 10 años',
        ageRange4: '11 a 15 años',
        ageKids: '¿Cuál es la franja etaria?',
        describeHere: 'Describa aquí',
        yourAddress: 'Su dirección de cobro',
        specialDate: {
          title: '¿Estará celebrando alguna fecha especial?',
          birthday: 'Cumplesaños',
          anniversary: 'Aniversario de casamiento',
          honeymoon: 'Luna de miel',
          vacation: 'Vacaciones',
          kidsVacation: 'Vacaciones de los niños',
          remoteWork: 'Trabajo remoto',
          digitalNomad: 'Nómada digital',
          others: 'Otros',
        },
        specialNeeds: {
          title:
            '¿Usted o alguien que viaja con usted es portador de necesidades especiales?',
          tellUs: 'Cuéntanos un poco sobre esas necesidades',
        },
        theme: {
          title:
            'Cuéntale un poco más a su especialista sobre los temas que pretende abordar en sala.',
          addTheme: 'Agregar tema',
        },
        probableDate: {
          probableDate: '¿Ya tiene una fecha probable para viajar?',
          probableDateText:
            'Estas informaciones orientan a su Travel Expert y ayudan en el planeamiento.',
          departureDate: 'Fecha de salida',
          from: 'De',
          origin: 'Origen',
          to: 'Para',
          destiny: 'Destino',
          airplane: 'Avión',
          transportation: 'Medios de transporte',
          duration: 'Tiempo de permanencia',
          add: 'Agregar más destinos',
        },
        expectations: {
          title:
            '¿Cuáles son las principales expectativas para su próximo viaje?',
          label:
            'Ej: Vivir la ciudad como un local, quedarse en el campo, practicar deportes, aventuras, gastronomía, experimentar vinos, cultura, espiritualidad.',
          placeholder: 'Describa aquí',
        },
        confirmedPayment: {
          title: 'Pago confirmado',
          paragraph1: 'Su pago fue procesado',
          rescheduled:
            'Su pago fue procesado, y la reunión fue reagendada en el sistema.',
          button: 'Volver a la página de inicio',
          goAhead: 'Continuar',
        },
        failedPayment: {
          title: 'Identificamos un fallo en su pago',
          subtitle: 'Su pago no fue procesado, intente nuevamente.',
          paragraph1:
            'No se preocupe, si necesita salir del sitio en este momento, puede realizar el pago más tarde.',
          payOtherTime: 'Pagar después',
        },
        noNeighborhood: 'Barrio no informado',
        travelMotivation: {
          title: 'Motivación de viaje',
          backpacker: 'Mochilero',
          economic: 'Económico',
          intermediate: 'Intermedio',
          luxury: 'Lujo',
          preferNotToSay: 'Prefiero no decirlo',
          intermediary: 'Intermedio',
          lux: 'Lujo',
          higher: 'Superior',
          other: 'Prefiero no decirlo',
          budget: '¿Cuál es su presupuesto de viaje?',
          leisure: 'Ocio',
          business: 'Negocios',
          leisureAndBusiness: 'Ocio y negocios',
          hostel: 'Hostal',
          superior: 'Superior',
          luxuryHotel: 'Hotel de lujo',
          superLuxuryHotel: 'Hotel de super lujo',
          restaurantRecommendation: 'Recomendación de restaurante',
          intermediateRestaurant: 'Restaurante intermedio',
          refinedRestaurant: 'Restaurante refinado',
          hauteCuisineRestaurant: 'Restaurante de alta cocina',
          hotelRecommendation: 'Recomendación de hoteles',

          birthday: 'Cumpleaños',
          anniversary: 'Aniversario de casamiento',
          honeymoon: 'Luna de miel',
          vacation: 'Vacaciones',
          kidsVacation: 'Vacaciones de los niños',
          remoteWork: 'Trabajo remoto',
          digitalNomad: 'Nómada digital',
        },
        confirmModal: {
          generalRules: 'Reglas generales',
          titlePresential: 'Encuentro presencial - Tour Guiado',
          titleOnline: 'Reunión Online',
          codeOfConduct: 'He leído y acepto el',
          linkCode: 'código de conducta',
          ofPlatform: 'de la plataforma',
          contactEmail: 'Preguntas: contactus@contenta360.com',
          advisor: 'Asesor de Viajes:',
          cancelReschedule: 'Para cancelaciones y reprogramaciones',
          whereMeeting: '¿Dónde será la reunión?',
          cancelNoPenalty:
            'Sin penalización si se cancela o se reprograma con más de 48 horas de antelación.',
          cancelPenalty:
            'Penalización del 100% del valor pagado si se cancela o se reprograma con menos de 48 horas de antelación.',

          onlineMeeting: {
            whereMeeting: '¿Dónde será la reunión?',
            reservedTime: 'Tiempo reservado',
            meetingInfo1:
              'Su reunión está programada para las 11:00 del día 16 de septiembre de 2023. Duración: 01 hora',
            meetingLocation:
              'El enlace para la videoconferencia se enviará después de confirmar el pago y la reserva.',
            generalRules: 'Reglas generales',
            briefing:
              'Después de realizar el pago, complete el Briefing de su viaje con información para que su Asesor de Viajes lo atienda mejor.',
            allowedParticipants:
              'Permitimos hasta 4 personas en la sala de videoconferencia.',
          },

          presentialMeeting: {
            reservedTime: 'Tiempo reservado',
            meetingInfo1:
              'Encuentro presencial programado para las 11:00 del día 16 de septiembre de 2023. Paquete de 03 horas',
            meetingInfo2:
              'El chat para comunicarse con su Asesor de Viajes estará disponible 48 horas antes del inicio de su recorrido.',
            meetingLocation:
              'El enlace para la videoconferencia se enviará después de confirmar el pago y la reserva.',
            generalRules: 'Reglas generales',
            afterPayment:
              'Después de realizar el pago, complete el Briefing de su viaje.',
          },
          alreadyParticipate: {
            title:
              '¿Ya has participado en una reunión en línea con el Asesor de Viajes ${ADVISOR_NAME}?',
            text: 'Recomendamos hacer una reunión en línea previa al encuentro en persona para que tanto el Viajero como el Asesor de Viajes se conozcan.',
          },
          meetingPoint: {
            title: 'Punto de Encuentro',
            hotelName: 'Nombre del hotel donde te hospedas',
            hotelRequired: 'Se requiere el hotel',
            address: 'Dirección',
            addressRequired: 'Se requiere la dirección',
          },
          wishTour: {
            title: '¿Cómo deseas que sea tu tour?',
            label:
              'Considerando lo que se discutió en tu reunión de planificación, describe a tu Asesor de Viajes cómo te gustaría que sea tu tour.',
            placeholder: 'Describe aquí',
          },
          placesVisit: {
            title: '¿Qué atracciones te gustaría visitar?',
            placeholder: 'Describe aquí',
          },
          requiredPoint: {
            title: '¿Existe algún punto de parada obligatorio?',
            label: 'Especifica el punto obligatorio',
            addrequiredPoint: 'Agregar punto',
          },
          childrenOrOldPeople: {
            title: '¿Hay niños o personas mayores en tu grupo?',
          },
          restrictionFood: {
            title: '¿Hay alguien en tu grupo con restricciones alimentarias?',
            label: 'Especifica las restricciones',
            placeholder: 'Describe aquí',
          },
        },
      },

      historyCalls: {
        title: 'Historial de llamadas',
        futureCalls: 'Agendamientos futuros',
        pastCalls: 'Agendamientos pasados',
        noFutureCalls: 'No tienes agendamientos futuros',
        noPastCalls: 'No tienes agendamientos pasados',
      },

      resume: {
        title: 'Resumen',
        yourGuideNotes: 'Anotaciones de su guía',
        noNotes: 'No hay anotaciones',
        downloadChat: 'Clique para bajar o histórico do chat dessa reunion',
        yourInterests: 'Sus intereses',
        experience: 'Experiencia',
        yourSpecialties: 'Especialidades',
        attendmentArea: 'Área de atención',
        specialities: 'Especialidades',
        hotels: 'Hoteles',
        description: 'Descripción',
        scheduledFor: 'Agendado para',
        localTimeTraveller: 'Horario local del viajero',
        value: 'Valor',
        guideNotes: 'Anotaciones de su guía',
        packages: {
          title: '¿Te gustó la reunión? Contrata más horas',
          onlineMeeting: 'Reunión online',
          presentialMeeting: 'Tour presencial',
          package1h: 'Valor por hora: ',
          package4h: 'Pacote de 4h: ',
          package6h: 'Pacote de 6h: ',
          package8h: 'Pacote de 8h: ',
          package10h: 'Pacote de 10h: ',
        },
        noBriefing: 'El viajero no completó el Briefing',
        interestedIn: 'Intereses del viajero',
        travelReason: 'Motivo del viaje',
        budget: 'Presupuesto',
        hotelRecommendation: 'Recomendación de hotel',
        restaurantRecommendation: 'Recomendación de restaurante',
        topicsAddressMeeting: 'Temas abordados en la reunión',
        expectation: 'Principales expectativas para el viaje',
        probableDateTravel: 'Fecha probable para viajar',
        withWhoGoTravel: 'Número y relación de viajeros',
        specialDate: 'Fecha especial',
        hasSpecialNeed: '¿Alguien con necesidades especiales?',
      },
    },

    filtersTag: {
      beach: 'Playa',
      mountain: 'Montaña',
      urban: 'Urbano',
      cruise: 'Crucero',
      resort: 'Resort',
      gastronomy: 'Gastronomía',
      wine: 'Vino',
      adventure: 'Aventura',
      ecotourism: 'Ecoturismo',
      shopping: 'Compras',
      culture: 'Cultura',
      adrenaline: 'Adrenalina',
      sports: 'Deportes',
    },

    meeting: {
      buttons: {
        copy: 'Link',
        waitingLocation: 'Esperando ponto de encuentro',
      },

      recordNotes: {
        copy: 'Copie el enlace de la llamada para compartir con su grupo de viaje',
        message:
          'Esta reunión se está grabando y el conocimiento compartido en esta reunión es propiedad intelectual del presentador. Está estrictamente prohibido grabar y capturar capturas de pantalla sin el permiso previo por escrito del presentador o de Contenta360. La violación de estas pautas puede resultar en acciones legales y otras acciones apropiadas.',
        title: 'Aviso de Grabación, Confidencialidad y Propiedad Intelectual',
        text1:
          'Esta reunión será grabada y el conocimiento compartido en esta reunión es propiedad intelectual del presentador.',
        text2:
          'Está estrictamente prohibido grabar y capturar capturas de pantalla sin el permiso previo por escrito del presentador o de Contenta360. La violación de estas pautas puede resultar en acciones legales y otras acciones apropiadas.',
      },

      notification: {
        captions: '¡Transcripción automática en vivo habilitada!',

        copy: {
          title: 'Éxito',
          message: 'Link copiado',
        },
      },
      loading: 'Entrando en sesión...',
      lobby: {
        title: '¡Hola visitante, bienvenido!',
        text: 'Por favor ingrese su nombre para acceder a la reunión',
        input: { placeholder: 'Escriba aquí...' },
        button: {
          send: 'Entrar',
        },
      },

      preview: {
        labels: {
          question: '¿Listo para unirte?',
        },
        buttons: {
          tooltips: {
            audio: {
              select: 'Seleccionar sonido',
              start: 'Iniciar sonido',
              end: 'Finalizar audio',
            },
            camera: {
              select: 'Seleccionar cámara',
              start: 'iniciar cámara',
              end: 'cerrar camara',
            },
          },
          ready: 'Para participar',
        },
      },
      video: {
        notification: {
          endCall: {
            message: 'Reunión finalizada',
            description: 'Esta reunión ha sido finalizada por el anfitrión',
          },
        },
        languages: {
          EN: 'Inglés',
          ZH: 'Chino simplificado)',
          JA: 'Japonés',
          DE: 'Alemán',
          FR: 'Francés',
          RU: 'Ruso',
          PT: 'Portugués',
          'PT-BR': 'Portugués',
          ES: 'Español',
          KO: 'Coreano',
          IT: 'Italiano',
          EN1: 'Reservado',
          VI: 'Vietnamita',
          NL: 'Holandés',
          UK: 'Ucranio',
          AR: 'Arábica',
          BN: 'Bengalí',
          'ZH-HANT': 'Chino tradicional)',
          CS: 'Checo',
          ET: 'Estonio',
          FI: 'Finlandés',
          EL: 'Griego',
          HE: 'Hebreo',
          HI: 'Hindi',
          HU: 'Húngaro',
          ID: 'Indonesio',
          MS: 'Malayo',
          FA: 'Persa',
          PL: 'Polaco',
          RO: 'Rumano',
          SV: 'Sueco',
          TA: 'Tamil',
          TE: 'Telugu',
          TL: 'Tagalo',
          TR: 'Turco',
        },
        buttons: {
          endSession: 'finalizar sesión',
          exitSession: 'Salir de la sesión',
          tooltips: {
            audio: {
              select: 'Seleccionar sonido',
              start: 'iniciar sonido',
              end: 'Finalizar audio',
            },
            camera: {
              select: 'Seleccionar cámara',
              start: 'iniciar cámara',
              end: 'cerrar camara',
            },
            shareScreen: {
              select: [
                'bloquear pantalla',
                'Un participante puede compartir a la vez',
                'múltiples participantes pueden compartir simultáneamente',
              ],
            },
            recording: {
              start: 'empezar a grabar',
              pause: 'pausar la grabación',
              resume: 'seguir grabando',
              status: 'Estado del registro',
              stop: 'finalizar grabación',
            },
            chat: 'sesión de chat',
            leave: 'desconectar',
          },
        },
      },
    },

    toast: {
      pictureError: 'Error al guardar foto',
    },

    chat: {
      presentialTourData: 'Datos del Tour Presencial',
      reportAdvisor: 'Denunciar',
      messages: {
        you: 'Tú',
      },
      reportUserModal: {
        title: 'Denunciar',
        description:
          'Seleccione a continuación, por favor, el motivo de su denuncia, y no se preocupe, ella será anónima. Desde ya le agradecemos por ayudar a Contenta360 a continuar siendo un ambiente seguro y saludable para nuestra comunidad.',
      },
    },

    creditCard: {
      addCreditCard: 'Agregar tarjeta de crédito',
      invalidCardName: 'Nombre de tarjeta inválido',
      cardHolder: 'Nombre como aparece en la tarjeta',
      cardNumber: 'Número de la tarjeta',
      expireDate: 'Fecha de expiración',
      cvc: 'Código de seguridad',
      membershipPayment: 'Pago de membresía',
      editMembership: 'Editar membresía',
      cardHolderPlaceholder: 'Nombre como aparece en la tarjeta',
      emptyCardNumber: 'El número de la tarjeta es inválido',
      invalidCardNumber: 'El número de la tarjeta es inválido',
      emptyExpiryDate: 'La fecha de expiración es inválida',
      monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
      yearOutOfRange: 'El año de expiración no puede estar en el pasado',
      dateOutOfRange: 'La fecha de expiración no puede estar en el pasado',
      invalidExpiryDate: 'La fecha de expiración es inválida',
      emptyCVC: 'El código de seguridad es inválido',
      invalidCVC: 'El código de seguridad es inválido',
      saveCreditCard: 'Guardar tarjeta para futuras compras',
      finishPayment: 'Finalizar pago',
      finishingPayment: 'Finalizando pago...',
      removeCard: {
        title: 'Sacar tarjeta registrada',
        paragraph1: '¿Está seguro de que desea eliminar esta tarjeta?',
        paragraph2:
          'Si desea utilizarlo en el futuro, deberá completar toda la información nuevamente.',
        button: 'Sacar tarjeta',
      },
      deactivateMembership: {
        title: 'Cancelar suscripción',
        paragraph1: '¿Está seguro de que desea cancelar su suscripción?',
        paragraph2:
          'Su cuenta se pausará y no podrá realizar nuevas llamadas, <strong>pero puede consultar el historial cuando lo desee</strong>.',
        button: 'Cancelar suscripción',
        hasMeetingYetParagraph1:
          'Parece que aún tiene algunas <strong>reuniones agendadas</strong> y por eso no podrá desactivar su suscripción.',
        hasMeetingYetParagraph2:
          'Si tiene alguna pregunta, contáctenos por correo electrónico <a href="mailto:contactus@contenta360.com">contactus@contenta360.com</a>.',
      },
    },

    privacyPolicy: {
      title: 'Política de Privacidad',
    },
    termsAndConditions: {
      title: 'Términos y Condiciones',
    },

    notTalkedMentor: {
      paragraph1: 'Aún no tiene reuniones agendadas.',
      paragraph2:
        'Nuestros Travel Experts están esperando por usted, listos para ayudarlo!',
    },

    withoutMembership: {
      onlineAttend: 'Atienda online',
      presentialAttend: 'Atienda presencialmente',
      definePrice: 'Defina su precio',
      personalizeAgenda: 'Personalice su agenda',
      paragraph1:
        'Su registro ha sido aprobado para actuar como Travel Expert en el portal Contenta360.',
      paragraph2:
        '¡Suscríbase al plan mensual de proveedores de servicios de la plataforma y comience a captar clientes hoy mismo!',
      title: '¡Bienvenido {ADVISOR_NAME}!',
      mensalPlan: 'por mes',
      advisorPlan: 'Suscripción Travel Expert',
      support24h: 'Soporte técnico 24h',
      subjectToTerms: 'Sujeto a ',
      termsAndConditions: 'Términos y Condiciones',
      button: 'Suscribirse ahora',
      nophoto: {
        title: '¡Agregue una foto a su perfil!',
        paragraph1:
          'Este es un paso importante para que el cliente lo identifique cuando lo encuentre en persona.',
      },
    },

    faq: {
      title: '¿Cómo podemos ayudarlo?',
      searchPlaceholder: 'Busque por palabras clave',
    },

    scheduledTours: {
      meetingPoint: 'Punto de encuentro',
      newMeetingPoint: 'Nuevo punto de encuentro',
      changeMeetingPoint: 'Cambiar punto de encuentro',
      errors: {
        meetingPointRejected:
          'Punto de encuentro rechazado por el viajero. ¿Desea sugerir un nuevo punto?',
      },
    },

    meetingResume: {
      onlineMeeting: 'Reunión online',
      tourSuggestions: 'Sugerencias para el tour',
      presentialTour: 'Tour presencial',
      fillMeetingResume: 'Complete el resumen de la reunión',
      suggestionsForTour: 'Sugerencias para el tour',
      transportationTips: 'Consejos de transporte',
      securityTips: 'Consejos de seguridad',
      restaurantsAndBars: 'Restaurantes / Bares',
      attractionsToBeVisited: 'Atracciones que deben visitarse',
      recommendedTours: 'Tours recomendados',
      makeAMeetingResume: 'Haga un resumen de la reunión',
      dateTour: 'Fecha del tour',
      startTour: 'Inicio tour',
      endTour: 'Fin tour',
      zipCode: 'Código postal',
      street: 'Calle',
      number: 'Número',
      complement: 'Complemento',
      referencePoint: 'Punto de referencia',
      recommendationsFromYourTravelAdvisor:
        'Recomendaciones de su Travel Expert',
      tour1: 'Tour 1 - Describe here clearly a summary for tour suggestion',
      tour2: 'Tour 2 - Describe here clearly a summary for tour suggestion',
      resumeAboutTour: 'Haga un resumen sobre el tour',
      sendMeetingResume: 'Enviar resumen',
      welcomeTextPresential: `Hola $TRAVELLER_NAME! Estoy muy feliz de recibirte aquí en mi ciudad!`,
      // welcomeTextOnline: `Olá $TRAVELLER_NAME! Obrigado pela nossa reunião. Deixo aqui um resumo do que conversamos:`,
      welcomeTextOnline: `Hola $TRAVELLER_NAME! Gracias por nuestra reunión. Aquí hay un resumen de lo que hablamos:`,
    },
  },
};
