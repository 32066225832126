import { Col, Form, Input, Row } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';

function SecondStep({ contactsAdressInput }) {
  return (
    <Row gutter={8}>
      {contactsAdressInput.map((input) => (
        <Col key={input.name} xs={24} md={12}>
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={input.hasFeedback}
              label={input.label}
              name={input.name}
              placeholder={input?.placeholder}
              rules={[{ required: input.isRequired, message: input?.message }]}
            >
              {input.input || (
                <Input
                  onBlur={input?.onBlur}
                  className={input?.className}
                  disabled={input?.disabled}
                  placeholder={input?.placeholder || input.label}
                />
              )}
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      ))}
    </Row>
  );
}

export default SecondStep;
