/* eslint-disable no-param-reassign */
import axios from 'axios';
import Cookies from 'js-cookie';
import { CONTENTA_TOKEN } from 'constants/storages-values';

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
});

const PUBLIC_URLS = ['/public', 'highlights'];

function shouldSkipAuthorization(url) {
  return url.includes('/public') || PUBLIC_URLS.includes(url);
}

function getToken() {
  const storedToken = Cookies.get(CONTENTA_TOKEN);
  const auth0TokenKey = Object.keys(localStorage).find((key) =>
    key.startsWith('@@auth0spajs@@')
  );
  const auth0Token = localStorage.getItem(auth0TokenKey);
  return JSON.parse(auth0Token)?.body?.access_token || storedToken || '';
}

api.interceptors.request.use((config) => {
  if (shouldSkipAuthorization(config.url)) {
    return config;
  }

  const tokenToUse = getToken();
  if (tokenToUse) {
    config.headers.Authorization = `Bearer ${tokenToUse}`;
  }

  return config;
});

export default api;
