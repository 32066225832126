import * as _ from 'lodash';
import moment from 'moment';

function createAssociatedSince({ mentor }) {
  if (Number(mentor.associatedDay) < 10 && mentor.associatedDay.length === 1) {
    mentor.associatedDay = `0${mentor.associatedDay}`;
  }
  if (
    Number(mentor.associatedMonth) < 10 &&
    mentor.associatedMonth.length === 1
  ) {
    mentor.associatedMonth = `0${mentor.associatedMonth}`;
  }

  if (mentor.associatedYear) {
    return `${mentor.associatedYear}-${_.padStart(
      mentor.associatedMonth,
      2,
      '0'
    )}-${_.padStart(mentor.associatedDay, 2, '0')}`;
  }

  return mentor.classAssociation.associatedSince;
}

function createBirthDate({ mentor }) {
  if (Number(mentor.day) < 10 && mentor.day.length === 1) {
    mentor.day = `0${mentor.day}`;
  }
  if (Number(mentor.month) < 10 && mentor.month.length === 1) {
    mentor.month = `0${mentor.month}`;
  }

  if (mentor.year) {
    return `${mentor.year}-${_.padStart(mentor.month, 2, '0')}-${_.padStart(
      mentor.day,
      2,
      '0'
    )}`;
  }

  return mentor.birthDate;
}

function handleCustomSpecialty({ customSpecialty }) {
  if (typeof customSpecialty === 'string') {
    return {
      specialty: customSpecialty,
    };
  }
  return customSpecialty || null;
}

function convertValueInput(value) {
  if (typeof value === 'string') {
    const numericValue = parseFloat(value.replace(/[^\d.]/g, ''));
    return isNaN(numericValue) ? null : numericValue;
  }

  return value || null;
}

function createMentor({ mentor }) {
  return {
    birthDate: createBirthDate({ mentor }),
    currency: mentor.currency,
    email: mentor.email,
    languages: mentor.languages,
    mobilePhone: `${mentor.ddi} ${mentor.mobilePhone}`,
    name: mentor.name,
    nationality: mentor.nationality,
    summary: mentor.tellUsAboutYou.replace(/,+/g, '٫').replace(/"/g, "'"),
    surname: mentor.surname,
    valueHourVirtual: convertValueInput(mentor.valueHourVirtual),
    valueFourHours: convertValueInput(mentor.valueFourHours),
    valueSixHours: convertValueInput(mentor.valueSixHours),
    valueEightHours: convertValueInput(mentor.valueEightHours),
    valueTenHours: convertValueInput(mentor.valueTenHours),
    profilePhrase: mentor.profilePhrase,
    address: {
      id: mentor?.address?.id || mentor?.addressId || '',
      city: mentor.city,
      complement: mentor.complement,
      country: {
        id: mentor.country,
      },
      state: mentor.state,
      street: mentor.street,
      zipcode: mentor.zipcode,
    },
    specialties: mentor?.specialties?.map((specialty) => ({
      code: specialty,
    })),
    ...(mentor.customSpecialty && {
      customSpecialty: {
        specialty: mentor.customSpecialty,
      },
    }),
    classAssociation: {
      associatedSince: mentor.associatedYear
        ? moment(createAssociatedSince({ mentor })).format('YYYY-MM-DD')
        : '',
      name: mentor.association,
      registerNumber: mentor.registerNumber,
      guideDependsDescription: mentor.requiredLicenseDetails,
    },
    ...(mentor?.isTurismCompany === 'true' && {
      company: {
        document: mentor.documentCompany,
        name: mentor.nameCompany,
        position: mentor.role,
      },
    }),
    consultingRegion: {
      city: mentor.consultancyCity,
      country: mentor.consultancyCountry,
      details: mentor.consultancyDetails.replace(/,+/g, '٫').replace(/"/g, "'"),
      name: '',
      state: mentor.consultancyState,
    },
    customLanguage: {
      language: mentor.otherLanguages || '',
    },
    ...(mentor?.kanaName && {
      kana: {
        city: mentor.city,
        complement: mentor.complement,
        state: mentor.state,
        street: mentor.street,
        zipcode: mentor.zipcode,
        name: mentor.kanaName,
        surname: mentor.kanaSurname,
      },
    }),
    ...(mentor?.kanjiName && {
      kanji: {
        city: mentor.city,
        complement: mentor.complement,
        state: mentor.state,
        street: mentor.street,
        zipcode: mentor.zipcode,
        name: mentor.kanjiName,
        surname: mentor.kanjiSurname,
      },
    }),
  };
}

function mountMobilePhone({ phone, ddi }) {
  if (phone.includes(ddi)) {
    return phone;
  }

  return `${ddi} ${phone}`;
}

function getCustomLanguage({ mentor }) {
  const isCustomLanguageObject = typeof mentor.customLanguage === 'object';

  if (mentor.customLanguage?.language) {
    return {
      language: mentor.customLanguage.language,
    };
  }
  if (mentor?.otherLanguages || mentor?.customLanguage) {
    return isCustomLanguageObject
      ? mentor.customLanguage
      : {
          language: mentor.customLanguage,
        };
  }

  return null;
}

function updateMentor({ mentor }) {
  return {
    birthDate: createBirthDate({ mentor }),
    currency: mentor.currency,
    email: mentor.email,
    languages: mentor.languages,
    mobilePhone: mountMobilePhone({
      phone: mentor.mobilePhone,
      ddi: mentor.ddi,
    }),
    name: mentor.name,
    nationality: mentor.nationality,
    summary: mentor.tellUsAboutYou || mentor.summary,
    surname: mentor.surname,
    valueHourVirtual: convertValueInput(mentor.valueHourVirtual),
    valueFourHours: convertValueInput(mentor.valueFourHours),
    valueSixHours: convertValueInput(mentor.valueSixHours),
    valueEightHours: convertValueInput(mentor.valueEightHours),
    valueTenHours: convertValueInput(mentor.valueTenHours),
    createdAt: mentor.createdAt,
    updatedAt: mentor.updatedAt,
    status: mentor.status,
    profilePhrase: mentor.profilePhrase,
    address: {
      id: mentor?.address?.id || mentor?.addressId || null,
      city: mentor.city || mentor.address.city,
      complement: mentor.complement || mentor.address.complement || '',
      country: mentor.country || mentor.address.country,
      state: mentor.state || mentor.address.state,
      street: mentor.street || mentor.address.street,
      zipcode: mentor.zipcode || mentor.address.zipcode,
    },
    specialties: mentor?.specialties,
    customSpecialty: handleCustomSpecialty({
      customSpecialty: mentor?.customSpecialty,
    }),
    classAssociation: {
      associatedSince: mentor.associatedYear
        ? moment(createAssociatedSince({ mentor })).format('YYYY-MM-DD')
        : '',
      name: mentor.association || mentor.classAssociation.name || '',
      registerNumber:
        mentor.registerNumber || mentor.classAssociation.registerNumber || '',
      guideDependsDescription:
        mentor?.requiredLicenseDetails ||
        mentor?.classAssociation?.guideDependsDescription,
    },
    ...(mentor?.isTurismCompany === 'true' && {
      company: {
        document: mentor.documentCompany || mentor.company.document,
        name: mentor.association || mentor.classAssociation.name || '',
        position: mentor.role || mentor.company.position,
      },
    }),
    consultingRegion: {
      city: mentor.consultancyCity || mentor.consultingRegion.city,
      country: mentor.consultancyCountry || mentor.consultingRegion.country,
      details:
        mentor?.consultancyDetails?.replace(/,+/g, '٫').replace(/"/g, "'") ||
        mentor.consultingRegion.details,
      name: '',
      state: mentor.consultancyState || mentor.consultingRegion.state,
    },
    contact: {
      id: mentor?.contact?.id || null,
      facebook: mentor?.facebook || mentor?.contact?.facebook || '',
      instagram: mentor?.instagram || mentor?.contact?.instagram || '',
      twitter: mentor?.twitter || mentor?.contact?.twitter || '',
      tikTok: mentor?.tikTok || mentor?.contact?.tikTok || '',
      site: mentor?.site || mentor?.contact?.site || '',
    },
    customLanguage: getCustomLanguage({ mentor }),
    ...(mentor?.accountNumber && {
      financial: {
        accountNumber: mentor.accountNumber || mentor?.financial?.accountNumber,
        address: mentor.agencyStreet || mentor?.financial?.address,
        agencyNumber: mentor.agencyNumber || mentor?.financial?.agencyNumber,
        bankName: mentor?.bankName || mentor?.financial?.bankName,
        bankNumber: mentor?.bankNumber || mentor?.financial?.bankNumber,
        brazilianAccount:
          Number(mentor.brazilianAccount) ||
          mentor?.financial?.brazilianAccount,
        brazilianAgency:
          Number(mentor.brazilianAgency) || mentor?.financial?.brazilianAgency,
        brazilianBank:
          Number(mentor.brazilianBank) || mentor?.financial?.brazilianBank,
        brazilianDigit:
          Number(mentor.brazilianDigit) || mentor?.financial?.brazilianDigit,
        city: mentor?.agencyCity || mentor?.financial?.city || '',
        country: {
          id: mentor?.accountCountry || mentor?.financial?.country.id || '',
        },
        state: mentor?.agencyState || mentor?.financial?.state || '',
        ibanCode: mentor?.ibanCode || mentor?.financial?.ibanCode || '',
        swiftCode: mentor?.swiftCode || mentor?.financial?.swiftCode || '',
        zipCode: mentor?.agencyZipcode || mentor?.financial?.zipCode || '',
      },
    }),
    ...(mentor?.kanaName && {
      kana: {
        city: mentor.city,
        complement: mentor.complement,
        state: mentor.state,
        street: mentor.street,
        zipcode: mentor.zipcode,
        name: mentor.kanaName,
        surname: mentor.kanaSurname,
      },
    }),
    ...(mentor?.kanjiName && {
      kanji: {
        city: mentor.city,
        complement: mentor.complement,
        state: mentor.state,
        street: mentor.street,
        zipcode: mentor.zipcode,
        name: mentor.kanjiName,
        surname: mentor.kanjiSurname,
      },
    }),
    imageURL: mentor?.imageURL || '',
  };
}

export default { createMentor, updateMentor };
