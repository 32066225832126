import { useAuth0 } from '@auth0/auth0-react';
import RedirectLoader from 'components/RedirectingLoader';
import {
  CONTENTA_GIFT_CARD,
  CONTENTA_LOGIN_BTN,
  CONTENTA_REDIRECT,
  CONTENTA_SELECTED_SCHEDULE,
  CONTENTA_USER,
  STATE_SCHEDULE,
} from 'constants/storages-values';
import { STATUS_TYPE, USER_TYPES } from 'constants/types';
import { useUser } from 'contexts/userContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { canViewAdmin } from 'utils/isRole';
import isUnloggedRoute from 'utils/isUnloggedRoute';

function redirectToBriefing(navigate, selectedSchedule) {
  const storageData = {
    selectedSchedule: {
      date: selectedSchedule.date,
      startAt: selectedSchedule.startAt,
      endAt: selectedSchedule.endAt,
      presentialTour: { ...selectedSchedule.presentialTour },
    },
    advisor: selectedSchedule.advisor,
    isRedirect: true,
  };

  sessionStorage.setItem(STATE_SCHEDULE, JSON.stringify(storageData));
  navigate('/register');
}

export function Redirect() {
  const { user, isLoading } = useAuth0();
  const navigate = useNavigate();
  const { handleUserContext, userRole, whoAmI, whoAmILoading, isLoggingOut } =
    useUser();

  useEffect(() => {
    if (isLoading || whoAmILoading || !user || !whoAmI || isLoggingOut) {
      return;
    }

    handleUserContext(user);

    const redirectType = sessionStorage.getItem(CONTENTA_LOGIN_BTN);
    if (
      redirectType === 'advisor' &&
      (userRole === '' || userRole === undefined)
    ) {
      sessionStorage.removeItem(CONTENTA_LOGIN_BTN);
      navigate('/guide/register');
      return;
    }

    if (userRole === '') {
      navigate('/register');
      return;
    }

    switch (userRole) {
      case USER_TYPES.traveler:
        navigate('/home');
        break;
      case USER_TYPES.advisor:
        if (whoAmI.status === STATUS_TYPE.APPROVED) {
          navigate('/advisor/home');
        } else if (canViewAdmin(user)) {
          navigate('/admin');
        } else {
          navigate('/guide/register');
        }
        break;
      case USER_TYPES.admin:
        navigate('/admin');
        break;
      default:
        navigate('/register');
        break;
    }
  }, [
    isLoading,
    isLoggingOut,
    user,
    userRole,
    whoAmI,
    whoAmILoading,
    handleUserContext,
    navigate,
  ]);

  useEffect(() => {
    if (isLoading || !user) {
      return;
    }

    sessionStorage.removeItem(CONTENTA_USER);
    sessionStorage.setItem(CONTENTA_USER, JSON.stringify(user));
    handleUserContext(user);

    const pathname = sessionStorage.getItem(CONTENTA_REDIRECT);
    sessionStorage.removeItem(CONTENTA_REDIRECT);

    const isGiftAdvisorProfile =
      pathname?.includes('/gift-advisor-profile/') ?? false;
    if (isGiftAdvisorProfile) {
      navigate(pathname);
      return;
    }

    const isUnloggedPage = isUnloggedRoute(pathname);
    if (
      !isUnloggedPage &&
      (pathname === '/checkout' || pathname === '/checkout-gift')
    ) {
      const contentKey =
        pathname === '/checkout'
          ? CONTENTA_SELECTED_SCHEDULE
          : CONTENTA_GIFT_CARD;
      const hasScheduleToRedirect = sessionStorage.getItem(contentKey);
      if (hasScheduleToRedirect) {
        try {
          const state = JSON.parse(hasScheduleToRedirect);
          redirectToBriefing(navigate, state);
          return;
        } catch (error) {
          console.error('Error parsing stored schedule data:', error);
        }
      }
    }

    if (pathname && pathname !== '/redirecting') {
      navigate(pathname);
    }
  }, [isLoading, user, handleUserContext, navigate]);

  return <RedirectLoader />;
}

export default Redirect;
