import { useAuth0 } from '@auth0/auth0-react';
import logo from 'assets/images/logo.png';
import { Loader } from 'components/Loader';
import { CONTENTA_TOKEN } from 'constants/storages-values';
import { useUser } from 'contexts/userContext';
import Cookies from 'js-cookie';
import { useEffect } from 'react';

import * as S from './styles';

export function Logout() {
  const { logout } = useAuth0();
  const { setIsLoggingOut } = useUser();

  const removeLocalStorage = () => {
    Cookies.remove(CONTENTA_TOKEN);
    // sessionStorage.removeItem(CONTENTA_SELECTED_SCHEDULE);
    // sessionStorage.removeItem(CONTENTA_TIMER_CHECKOUT);
    // sessionStorage.removeItem(CONTENTA_USER);
    // sessionStorage.removeItem(CONTENTA_LOGIN_BTN);
    // sessionStorage.removeItem(STATE_SCHEDULE);
    // sessionStorage.removeItem(CONTENTA_REDIRECT);
    // sessionStorage.removeItem(CONTENTA_TOKEN);
    // sessionStorage.removeItem(CONTENTA_LOGIN_BTN);

    const keys = Object.keys(sessionStorage);
    keys.forEach((key) => {
      if (key.startsWith('@contenta-')) {
        sessionStorage.removeItem(key);
      }
    });

    let authTokenKey = Object.keys(localStorage).filter((key) =>
      key.startsWith('@@auth0spajs@@')
    );
    localStorage.removeItem(authTokenKey[0]);
  };

  const handleLogout = async () => {
    const baseUrl = window.location.origin;
    removeLocalStorage();
    setIsLoggingOut(false);
    const timeoutId = setTimeout(() => {
      logout({
        returnTo: baseUrl,
      });

      clearTimeout(timeoutId);
    }, 1000);
  };

  useEffect(() => {
    handleLogout();
  }, []);

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LoginLogo src={logo} alt="Contenta 360 logo" />
        <Loader />
      </S.LoginContainer>
    </S.Container>
  );
}
