import { User, useAuth0 } from '@auth0/auth0-react';
import { AxiosError } from 'axios';
import { CURRENCIES } from 'constants/currencies';
import { CONTENTA_TOKEN } from 'constants/storages-values';
import Cookies from 'js-cookie';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserService from 'services/UserService';
import { keyRole } from 'utils/isRole';
import isUnloggedRoute from 'utils/isUnloggedRoute';
import useSubscription from './contextsHooks/useSubscription';
import { USER_TYPES } from 'constants/types';

interface Subscription {
  currency: string;
  description: string;
  enabled: boolean;
  interval: string;
  intervalCount: number;
  lastPayment: string;
  name: string;
  nextPayment: string;
  startAt: string;
  trialDays: number;
  value: number;
}
interface UserContextProps {
  updateUserData: (data: Record<any, any>) => void;
  user: User | undefined;
  userCurrency: string;
  whoAmI: Record<any, any>;
  handleUserContext: (user: Record<any, any>) => void;
  isLoading: boolean;
  logout: () => Promise<void>;
  registerFieldsHelper: {
    isAdult: boolean;
    birthDate: null;
    isValidDate: boolean;
  };
  setToken: (token: any) => Promise<void>;
  userRole: string;
  setRegisterFieldsHelper: React.Dispatch<
    React.SetStateAction<{
      isAdult: boolean;
      birthDate: null;
      isValidDate: boolean;
    }>
  >;
  whoAmILoading: boolean;
  subscription: Subscription | null;
  isLoggingOut: boolean;
}

const UserContext = createContext<UserContextProps>({} as UserContextProps);

export function UserProvider({ children }: { children: React.ReactElement }) {
  const { user: authUser, isLoading } = useAuth0();
  const { subscription, getSubscription, subscriptionError } =
    useSubscription();
  const navigate = useNavigate();

  const [loggedUser, setLoggedUser] = useState<Record<any, any>>({});
  const [whoAmI, setWhoAmI] = useState<Record<any, any>>({});
  const [whoAmILoading, setWhoAmILoading] = useState(true);
  const [userCurrency, setUserCurrency] = useState('');
  const [userRole, setUserRole] = useState('');
  const [userError, setUserError] = useState<AxiosError | null>(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [registerFieldsHelper, setRegisterFieldsHelper] = useState({
    isAdult: true,
    birthDate: null,
    isValidDate: true,
  });

  function updateLocalToken(data: any) {
    if (!data) {
      return;
    }

    Cookies.set(CONTENTA_TOKEN, data, { secure: true, sameSite: 'strict' });
  }

  const logout = async () => {
    setIsLoggingOut(true);
    setLoggedUser({});
    setWhoAmI({});
    setUserRole('');
    navigate('/logout');
  };

  const handleUserContext = (user: Record<any, any>) => {
    setLoggedUser(user);
  };

  const setToken = async (token: string) => {
    setLoggedUser({ ...loggedUser, token });
    updateLocalToken(token);
  };

  const getUserCurrency = async () => {
    try {
      const response = await UserService.getUserCurrency();
      const countryByCurrency = CURRENCIES.find(
        (currency) => currency.code === response.currencyStandard
      ) || { name: 'USD' };
      setUserCurrency({
        ...response,
        countryName: response.name,
        name: countryByCurrency.name,
      });
    } catch (error) {
      setUserCurrency('USD');
    }
  };

  const getLoggedUser = async () => {
    setWhoAmILoading(true);
    const isRegisterPage =
      window.location.pathname.includes('/register') ||
      window.location.pathname.includes('/redirecting');
    try {
      if (!authUser) return;

      const role = authUser[keyRole]?.[0];
      setUserRole(role || '');

      const userRoles = authUser[keyRole] || [];
      const userRolesWithoutAdmin = userRoles.filter(
        (role: string) => role !== USER_TYPES.admin
      );

      const data = await UserService.getCurrentUser({
        role: userRolesWithoutAdmin[0],
      });
      setWhoAmI({
        ...loggedUser,
        ...authUser,
        ...data,
        isRegisterPage,
      });
      setUserError(null);
    } catch (error) {
      setWhoAmI({ ...loggedUser, ...authUser, isRegisterPage });
      if (error instanceof AxiosError) {
        const { pathname } = window.location;
        setUserError(error);
        const status = error?.response?.status || 0;
        if (pathname === '/') {
          return;
        }

        if (status === 404) {
          navigate('/guide/register');
          return;
        }

        const isRegisterPage = pathname.includes('/register');
        if (isRegisterPage) return;

        const isAdvisorUserEndpoint =
          error?.response?.request?.responseURL.includes('advisor/user');

        const isUnlogedRoute = isUnloggedRoute(pathname);
        if (isAdvisorUserEndpoint && !isUnlogedRoute) {
          setTimeout(getLoggedUser, 10000);
          return;
        }
      }
    }
    setWhoAmILoading(false);
  };

  const updateUserData = (data: Record<any, any>) => {
    setWhoAmI({
      ...loggedUser,
      ...data,
    });
  };

  const reloadUserData = async () => {
    await getLoggedUser();
  };

  useEffect(() => {
    (async () => {
      if (!isLoading && authUser) {
        setLoggedUser(authUser);
        await getLoggedUser();
        await getSubscription(authUser[keyRole][0]);
      }
    })();
  }, [authUser, isLoading]);

  useEffect(() => {
    getUserCurrency();
  }, []);

  const value = useMemo(
    () => ({
      handleUserContext,
      isLoading,
      isLoggingOut,
      logout,
      registerFieldsHelper,
      reloadUserData,
      setRegisterFieldsHelper,
      setToken,
      subscription,
      subscriptionError,
      updateUserData,
      user: loggedUser,
      userCurrency,
      userError,
      userRole,
      whoAmI,
      whoAmILoading,
      setIsLoggingOut,
    }),
    [
      isLoading,
      isLoggingOut,
      loggedUser,
      registerFieldsHelper,
      subscription,
      subscriptionError,
      userCurrency,
      userError,
      userRole,
      whoAmI,
      whoAmILoading,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useUser = () => React.useContext(UserContext);
