import PropTypes from 'prop-types';

function AccessibilityIcon({ color, height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      // width={56}
      height={46}
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      viewBox="843 1368 793 806"
    >
      <path
        d="m1258.29 1563.4-.03.03a26.902 26.902 0 0 0-19.1-7.91c-7.47 0-14.22 3.02-19.11 7.91l-.03-.03a27.043 27.043 0 0 0-7.88 19.14c0 7.46 3.03 14.22 7.91 19.1a26.925 26.925 0 0 0 19.11 7.91c7.46 0 14.22-3.02 19.1-7.91a26.902 26.902 0 0 0 7.91-19.1c0-7.49-3.01-14.26-7.88-19.14zm-19.13-154.7c95.28 0 181.55 38.63 244 101.08 62.45 62.44 101.07 148.72 101.07 244s-38.62 181.55-101.07 244c-62.45 62.44-148.72 101.07-244 101.07s-181.56-38.63-244-101.07c-62.45-62.45-101.07-148.72-101.07-244s38.62-181.56 101.07-244c62.44-62.45 148.72-101.08 244-101.08zm-54.22 541.97c-2.75 8.68-12.02 13.49-20.71 10.74-8.68-2.75-13.49-12.03-10.74-20.71l64.97-203.4-110.63-36.23c-8.67-2.82-13.41-12.13-10.6-20.8 2.82-8.66 12.13-13.4 20.8-10.59l121.13 39.67 121.13-39.67c8.66-2.81 17.97 1.93 20.79 10.59 2.82 8.67-1.92 17.98-10.59 20.8l-110.64 36.23 64.97 203.4c2.75 8.68-2.06 17.96-10.74 20.71s-17.96-2.06-20.71-10.74l-54.21-169.74-54.22 169.74zm274.84-417.52c-56.46-56.45-134.46-91.38-220.62-91.38s-164.17 34.93-220.63 91.38c-56.45 56.46-91.37 134.47-91.37 220.63s34.92 164.16 91.37 220.62c56.46 56.46 134.47 91.38 220.63 91.38s164.16-34.92 220.62-91.38c56.46-56.46 91.38-134.46 91.38-220.62s-34.92-164.17-91.38-220.63z"
        style={{
          fill: '#8d8b8d',
        }}
      />
    </svg>
  );
}

AccessibilityIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

AccessibilityIcon.defaultProps = {
  width: 53,
  height: 40,
  color: '#868484',
};

export default AccessibilityIcon;
