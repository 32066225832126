import logoImg from 'assets/images/logo.png';
import Avatar from 'components/Avatar';
import { FlagMenu } from 'components/FlagMenu';
import { Loader } from 'components/Loader';
import { APP_LANGUAGES } from 'constants/app-languages';
import { useLanguage } from 'contexts/languageContext';
import { useUser } from 'contexts/userContext';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FinishedRegister from './FinishedRegister';
import RegisterSteps from './RegisterSteps';
import { RegisterProvider } from './context/useRegister';

import * as S from './styles';

export function GuideRegister() {
  const { i18n, t } = useTranslation();
  const { whoAmI, isLoading } = useUser();
  const { setCurrentLanguage } = useLanguage();
  const [alreadyRegistered, setAlreadyRegistered] = useState(null);
  const [isSaving, setSaving] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(null);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  useEffect(() => {
    if (isLoading) return;

    if (whoAmI?.createdAt) {
      setAlreadyRegistered(true);
      setIsLoadingPage(false);
      return;
    }
    setSaving(false);

    if (whoAmI?.isRegisterPage) {
      setIsLoadingPage(false);
      return;
    }
  }, [whoAmI, isLoading]);

  useEffect(() => {
    const languageObject = APP_LANGUAGES.find(
      (language) => language.key === i18n?.language
    );
    setCurrentLanguage(languageObject?.key);
  }, [i18n.language]);

  if (isLoadingPage) {
    return <Loader />;
  }

  return (
    <RegisterProvider
      setRegisterSuccess={setRegisterSuccess}
      setSaving={setSaving}
    >
      <S.Container>
        {isSaving && <Loader />}
        <S.GuideNavbar>
          <Avatar src={logoImg} alt="" />
        </S.GuideNavbar>

        <S.Content>
          <S.CardContainer>
            <header>
              <div>
                <h1>{t('guides.register')}</h1>
              </div>
              <FlagMenu />
            </header>
            {registerSuccess || alreadyRegistered ? (
              <FinishedRegister />
            ) : (
              <RegisterSteps />
            )}
          </S.CardContainer>
        </S.Content>
      </S.Container>
    </RegisterProvider>
  );
}
