import styled, { css } from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

const CommonBorder = css`
  border: 1px solid ${({ theme }) => theme.colors.neutralBlack[30]}!important;
  border-radius: 8px !important;
`;

const ContentaInputGroup = styled.div`
  &.vertical-form {
  }

  .ant-input-affix-wrapper {
    ${CommonBorder};
    height: 52px;

    .ant-input-suffix {
      margin: 0;
      padding: 0;
    }
  }

  .ant-input-affix-wrapper-has-feedback {
    padding: 0;
    padding: 0 16px;
    position: relative;
  }

  ${({ isFocused }) =>
    isFocused &&
    css`
      background-color: ${({ theme }) =>
        theme.colors.neutralWhite[10]}!important;
      border-radius: 8px;
    `};

  .ant-form-item-label {
    label {
      color: ${({ theme }) => theme.colors.neutralBlack[40]};
      font-size: ${convertPxToRem(12)};
      letter-spacing: 0.02em;
      font-feature-settings: 'pnum' on, 'lnum' on;
      font-family: ${({ theme }) => theme.fonts.texts};
    }
  }

  .ant-picker {
    ${CommonBorder};
    height: 48px;
    width: 100%;
  }

  .ant-select {
    min-height: 48px;
    .ant-select-selector {
      ${CommonBorder};
      min-height: 50px;
      height: auto;
    }
    .ant-select-selection-item {
      align-items: center;
      display: flex;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-feature-settings: 'lnum';
      height: 48px;
    }
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    padding-top: 0;
  }

  .ant-form-item-control-input-content {
    .ant-input {
      /* change border focus */
      &:focus {
        box-shadow: none;
      }
    }
    .input__border {
      ${CommonBorder};
    }
  }

  .ant-currency {
    padding: 2px;
    position: relative;
    top: 2px;
  }

  .ant-select-selection-placeholder {
    position: relative;
  }

  .is-disabled {
    .ant-form-item-label {
      label {
        color: #cbcbcb !important;
      }
    }
    .ant-input-disabled {
      background-color: #fff !important;
    }
  }

  .ant-select-selection-item {
    font-family: ${(props) => props.theme.fonts.primary};
    position: relative;
  }

  .ant-select-multiple {
    .ant-select-selection-item {
      position: relative;
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
      background: ${({ theme }) => theme.colors.neutralBlack[10]};
      border-radius: 8px;
      height: 36px;
      top: -1px;
    }

    .ant-select-selection-item-remove {
      color: ${({ theme }) => theme.colors.neutralWhite[10]};
    }
  }

  input {
    border-radius: 8px;
    color: #757575;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-feature-settings: 'lnum';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    height: 100%;
    min-height: 48px;
  }

  .contenta-input {
    border: 1px solid #4a4647 !important;
    border-radius: 8px !important;
  }

  input.ant-input.is-number-input {
    ${CommonBorder};
  }
`;

export { ContentaInputGroup };
