import { Col, Form, Input, Row } from 'antd';
import { ContentaInputGroup } from 'components/Styled/ContentaInputGroup';
import usePersonalAddressInputs from 'hooks/Register/usePersonalAddress';
import { useEffect } from 'react';

function AddressInputs({ form }) {
  const { addressInputs, zipcodeValues } = usePersonalAddressInputs();

  useEffect(() => {
    for (const key in zipcodeValues) {
      form.setFieldsValue({
        [key]: zipcodeValues[key],
      });
    }
  }, [zipcodeValues]);

  return (
    <Row gutter={24}>
      {addressInputs.map((input) => (
        <Col key={input.name} xs={24} sm={input.col}>
          <ContentaInputGroup>
            <Form.Item
              hasFeedback={input.hasFeedback}
              label={input.label}
              name={input.name}
              rules={[{ required: input.isRequired, message: input?.message }]}
            >
              {input.input || (
                <Input
                  onBlur={input?.onBlur}
                  className={input?.className}
                  disabled={input?.disabled}
                  style={{ ...input?.style }}
                />
              )}
            </Form.Item>
          </ContentaInputGroup>
        </Col>
      ))}
    </Row>
  );
}

export default AddressInputs;
