import { add, format } from 'date-fns';
import BookingService from 'services/BookingService';
import getTimeZone from 'utils/getTimeZone';

const useBookingAdvisor = () => {
  const getAdvisorSchedules = async (advisorId) => {
    const timezone = getTimeZone();
    const currentDay = format(new Date(), 'yyyy-MM-dd');
    const endDay = format(add(new Date(), { days: 2 }), 'yyyy-MM-dd');
    try {
      const { dates } = await BookingService.getBookingByAdvisorId({
        advisorId,
        params: `?startsAt=${currentDay}&endsAt=${endDay}&timeZone=${timezone}`,
      });

      return dates;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    getAdvisorSchedules,
  };
};

export default useBookingAdvisor;
