import { useAuth0 } from '@auth0/auth0-react';
import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import { Navigate, Outlet } from 'react-router-dom';
import { canViewAdmin } from 'utils/isRole';

export function ProtectedRouteAdmin({ user, children }) {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <RedirectLoader />;
  }

  if (!isAuthenticated && !isLoading) {
    return <Navigate to="login" />;
  }

  if (!canViewAdmin(user)) {
    return <Navigate to="guide/register" />;
  }

  return <ContainerApp>{children || <Outlet />}</ContainerApp>;
}
