/* eslint-disable react/jsx-no-bind */
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Empty, Tag } from 'antd';
import { Loader } from 'components/Loader';
import RedirectLoader from 'components/RedirectingLoader';
import ScrollToTop from 'components/ScrollToTop';
import { SearchMentor } from 'components/SearchMentor';
import { useSearch } from 'contexts/searchContext';
import { useUser } from 'contexts/userContext';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import { errorHandler } from 'utils/errorHandler';
import { FoundMentor } from './FoundMentor';
const { countries, zones } = require('moment-timezone/data/meta/latest.json');

import * as S from './styles';

export const FindMentor = () => {
  const { t } = useTranslation();
  const { state, replace } = useLocation();
  const {
    foundAdvisors,
    isLastPage,
    searchedParams,
    tagFilters,
    getAdvisors,
    getMoreData,
    handleFilterClick,
    serializeFilters,
    setFoundAdvisors,
    setIsLastPage,
    setSearchedParams,
  } = useSearch();
  const { userCurrency } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [isRemovingFilter, setIsRemovingFilter] = useState(false);
  const userCountry = useMemo(() => {
    const timeZoneToCountry = {};

    Object.keys(zones).forEach((z) => {
      timeZoneToCountry[z] = countries[zones[z].countries[0]].abbr;
    });

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return timeZoneToCountry[timeZone];
  }, []);

  const searchAdvisors = async (filters, isSearchbar) => {
    let newParams = state?.searchedParams || '';

    let finalFilters = '';
    if (isSearchbar) {
      const serializedFilters = serializeFilters(filters);
      setSearchedParams(serializedFilters);
      newParams = filters;
      finalFilters = serializedFilters;
    } else {
      const stringFilters = createStringFilters(filters);
      const searchParam = serializeFilters(newParams);
      finalFilters = mergeFilters(stringFilters, searchParam);
    }

    setIsLoading(true);
    try {
      const response = await getAdvisors(finalFilters);
      setIsLastPage(response.last || false);
      setFoundAdvisors(response?.content || []);
    } catch (error) {
      errorHandler(error);
      setFoundAdvisors([]);
    }
    setIsLoading(false);
  };

  const createStringFilters = (filters) => {
    if (typeof filters === 'object') {
      return Object.entries(filters)
        .filter(([_, value]) => value)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
    } else {
      return filters;
    }
  };

  const mergeFilters = (stringFilters, searchParam) => {
    let mergedFilters = stringFilters;
    if (stringFilters !== searchParam) {
      mergedFilters += `&${searchParam}`;
    }
    if (mergedFilters.endsWith('&')) {
      mergedFilters = mergedFilters.slice(0, -1);
    }
    return mergedFilters;
  };

  const handleRemoveFilter = (filter) => {
    handleFilterClick(filter);
    setIsRemovingFilter(true);
  };

  useEffect(() => {
    if (isRemovingFilter) {
      setIsRemovingFilter(false);
      let filters = state?.filters || '';
      filters = filters?.replace(`topic=${tagFilters?.[0]?.id}`, '') || '';
      searchAdvisors(filters);
      replace?.({ state: { ...state, filters } });

      return;
    }

    const filters = searchedParams || state?.filters || '';
    const tagId = tagFilters?.[0]?.id || tagFilters?.[0]?.code;
    const specialtyId = state?.specialty?.[0]?.id;
    const shouldAddTagFilter = tagFilters.length > 0 && tagId !== specialtyId;

    let newFilters = filters;

    if (specialtyId) {
      newFilters =
        Object.entries(newFilters)
          .map(([key, value]) => `${key}=${value}`)
          .join('&') + `&topic=${specialtyId}`;
    }

    if (shouldAddTagFilter) {
      const topic = `topic=${tagId}`;
      newFilters = filters
        ? `${Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&')}&${topic}`
        : topic;
      replace?.({ state: { ...state, filters: newFilters } });
    }

    searchAdvisors(newFilters);
  }, [tagFilters, state?.filters, state?.specialty?.[0]?.id]);

  return (
    <Suspense fallback={<RedirectLoader />}>
      <ScrollToTop />
      <S.Container
        isSearchPage={window.location.pathname.includes('search-advisor')}
      >
        {isLoading && <Loader />}
        <S.Header>
          <SearchMentor
            handleFilterClick={handleFilterClick}
            initialValues={searchedParams}
            handleSearchClick={searchAdvisors}
          />
        </S.Header>

        <S.PageContent>
          <div className="search-header">
            <div className="search-header__filters">
              {tagFilters.map((tag, index) => (
                <Tag
                  key={`tag-${index}`}
                  closable
                  onClose={() => handleRemoveFilter(tag)}
                >
                  {t(
                    `specialties.${tag.value || tag.label || tag.name || tag}`
                  )}
                </Tag>
              ))}
            </div>
          </div>
          <Divider />

          {foundAdvisors?.length === 0 ? (
            <Empty description={t('guides.empty')} />
          ) : (
            <InfiniteScroll
              dataLength={foundAdvisors?.length}
              next={getMoreData}
              hasMore={!isLastPage}
              loader={
                <div style={{ textAlign: 'center' }}>
                  <LoadingOutlined />
                </div>
              }
            >
              {foundAdvisors?.map((advisor) => (
                <FoundMentor
                  currency={userCurrency.currencyStandard}
                  params={searchedParams}
                  country={userCountry}
                  advisor={advisor}
                  key={advisor?.id}
                />
              ))}
            </InfiniteScroll>
          )}
        </S.PageContent>
      </S.Container>
    </Suspense>
  );
};

export default FindMentor;
