/* eslint-disable consistent-return */

import logoDegrade from 'assets/images/logo-degrade.webp';
import logo from 'assets/images/logo-white.png';
import { CONTENTA_LOGIN_BTN } from 'constants/storages-values';
import { useUser } from 'contexts/userContext';
import { useNavigate } from 'react-router-dom';
import checkIsRegistered from 'utils/checkIsRegistered';
import { isUserAdmin, isUserGuide } from 'utils/isRole';
import isUnloggedRoute from 'utils/isUnloggedRoute';

const isHome =
  window.location.pathname === '/home' || window.location.pathname === '/';
const useNavbar = () => {
  const { userRole, whoAmI } = useUser();
  const navigate = useNavigate();
  const hasUser = Object.keys(whoAmI).length > 0;
  const isRegistered = checkIsRegistered({ whoAmI });

  function handleLogin() {
    sessionStorage.setItem(CONTENTA_LOGIN_BTN, 'traveler');
    navigate('/login');
  }

  const handleBeTravelAdvisor = () => {
    sessionStorage.setItem(CONTENTA_LOGIN_BTN, 'advisor');
    navigate('/login');
  };

  const handleFinishRegister = ({ btnClicked }) => {
    if (btnClicked === 'traveler') {
      navigate('/register');
    } else {
      navigate('/guide/register');
    }
  };

  function checkURLRedirect() {
    if (hasUser && isRegistered) {
      return isUserGuide(userRole) || isUserAdmin(userRole)
        ? '/advisor/home'
        : '/home';
    } else if (hasUser && !isRegistered) {
      return '/register';
    } else {
      return '/';
    }
  }

  const getLogo = () => {
    const { pathname } = window.location;
    const isPublic = isUnloggedRoute(pathname);
    if (!hasUser && isPublic) {
      return logoDegrade;
    }
    if (!isRegistered) {
      return logoDegrade;
    }
    if (!hasUser) {
      return logo;
    }

    return hasUser ? logo : logoDegrade;
  };

  const getMenuIconType = () => {
    if (!hasUser && isHome) {
      return 'UNLOGGED';
    }

    if (!isRegistered || !userRole) {
      return 'UNLOGGED';
    }

    return 'DEFAULT';
  };

  return {
    checkURLRedirect,
    getLogo,
    getMenuIconType,
    handleBeTravelAdvisor,
    handleFinishRegister,
    handleLogin,
  };
};

export default useNavbar;
