import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegister } from '../context/useRegister';

const useChangeTabs = ({
  handleSubmitForm,
  handleValidForm,
  setButtonText,
  setIsFormValid,
  setLanguageError,
}) => {
  const { t } = useTranslation();
  const { setFormValues, formValues, form } = useRegister();
  const [amountBacked, setAmountBacked] = useState(0);
  const [amountNextClicked, setAmountNextClicked] = useState(0);
  const [hasBacked, setHasBacked] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [tabHeader, setTabHeader] = useState({
    tab1: <span>{t('guides.step')} 1</span>,
    tab2: <span>{t('guides.step')} 2</span>,
    tab3: <span>{t('guides.step')} 3</span>,
    tab4: <span>{t('guides.step')} 4</span>,
  });

  async function validateTabForm() {
    try {
      await form.validateFields();
      setFormValues({
        ...formValues,
        ...form.getFieldsValue(),
      });

      return true;
    } catch (error) {
      setTabHeader({
        ...tabHeader,
        [`tab${activeTab}`]: (
          <span>
            {t('guides.step')} {activeTab}
          </span>
        ),
      });
      return false;
    }
  }

  async function handleChangeTab({ clickedTab, isBacking = false }) {
    if (isBacking) {
      setHasBacked(true);
      setAmountBacked((prev) => prev + 1);
      setAmountNextClicked((prev) => prev - 1);
      setActiveTab(`${parseInt(activeTab, 10) - 1}`);
      setButtonText('continue');
      return;
    }

    if (hasBacked && amountBacked > amountNextClicked) {
      setAmountNextClicked((prev) => prev + 1);
      setAmountBacked((prev) => prev - 1);
      setActiveTab(`${parseInt(activeTab, 10) + 1}`);
      return;
    }

    setHasBacked(false);
    const isFormTabValid = await validateTabForm();
    setIsFormValid(isFormTabValid);

    if (!isFormTabValid) return;

    if (
      activeTab === '3' &&
      (!formValues.languages || formValues.languages.length === 0)
    ) {
      setLanguageError(true);
      return;
    }

    setLanguageError(false);

    if (activeTab === '4' && !clickedTab && isFormTabValid) {
      handleSubmitForm();
      return;
    }

    handleValidForm(
      isFormTabValid,
      clickedTab || `${parseInt(activeTab, 10) + 1}`
    );
  }

  return {
    setActiveTab,
    activeTab,
    tabHeader,
    setTabHeader,
    handleChangeTab,
  };
};

export default useChangeTabs;
