import PropTypes from 'prop-types';

function LuxuryTravel({ color, height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={90}
      height={50}
      viewBox="0 0 19084.54 19905.12"
    >
      <path
        d="M18964.46 6999.83c-3.25 4.79-6.73 9.45-10.45 13.97L9931.44 17907.88c-14.52 17.6-31.81 32.08-51.32 43.52-89.26 74.62-235.96 119.54-361.39 119.54-125.43 0-208.59-44.92-297.85-119.53-19.51-11.43-36.82-25.92-51.34-43.53L196.86 7074.03c-48.53-38.27-90-85.1-122.13-138.21-46.71-70.2-74.4-157.41-74.39-251.89 0-7.67.19-15.28.54-22.85 1.81-106.02 35.71-204.22 92.39-285.33 2.71-4.88 5.54-9.73 8.53-14.51l2719.96-4362.92c78.98-126.49 230.88-183.35 383.44-158.39 9.4-.55 18.86-.86 28.41-.86l12637.33-.18c207.11 0 381.59 130.65 434.07 308.43l2683.86 4213.92a334.388 334.388 0 0 1 25.81 50.4c44.19 75.92 69.52 164.17 69.52 258.34 0 125.54-45.01 240.56-119.74 329.85zm-5488.3-736.15h4454.08l-2250.86-3534.06-2203.22 3534.06zm-2920.36-3584.1 4227.77-.06-2113.9 3319.04-2113.87-3318.98zm-3240.57 3584.1h4454.08L9518.45 2729.62 7315.23 6263.68zm-2994.27-3584.1 4227.77-.06-2113.9 3319.04-2113.87-3318.98zm-3167.84 3584.1H5607.2L3356.34 2729.62 1153.12 6263.68zm160.1 840.52h4431.84l3247.91 9272.73L1313.22 7104.2zm5322.43 0h5819.11l-2936.31 8316.03-2882.8-8316.03zm11104.23 0h-4431.84l-3247.91 9272.73 7679.75-9272.73z"
        style={{
          fill: '#8d8b8d',
        }}
      />
    </svg>
  );
}

LuxuryTravel.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

LuxuryTravel.defaultProps = {
  width: 53,
  height: 40,
  color: '#868484',
};

export default LuxuryTravel;
