import styled from 'styled-components';
import convertPxToRem from 'utils/convert-px-rem';

import bgImg from '../../../assets/images/background-login.png';
import { FIXED_VALUES } from '../../../constants/css-fixed-values';
import PaddingContainer from '../../../styles/paddingContainer';

export const Container = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  padding: 0 0 24px;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    background-image: url(${bgImg});
  }
`;

export const GuideNavbar = styled.nav`
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  height: ${FIXED_VALUES.navbar}px;
  justify-content: space-between;
  ${PaddingContainer};

  img {
    width: 190px;
  }

  .ant-space-item {
    cursor: pointer;
    color: #5e5e5e;
    font-family: ${(props) => props.theme.fonts.text};
    font-feature-settings: 'pnum' on, 'lnum' on;
    font-size: 12px;
    font-weight: 500;
  }

  .icon-flag {
    font-size: 24px;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  justify-content: center;
  margin: 16px auto;
  max-width: 880px;
  padding: 0 16px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    margin: 24px auto 48px;
    padding: 0;
  }

  .initial-font-transform {
    text-transform: initial;

    label {
      text-transform: initial !important;
    }
  }

  .label-title {
    color: ${({ theme }) => theme.colors.neutralBlack[40]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .label-span {
    align-self: flex-start;
    display: block;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: 1rem;
    font-weight: 800;
  }

  .form-photo {
    margin-bottom: 24px;
    margin-left: 36px;

    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      align-items: center;
      display: flex;
      flex-flow: column wrap;
      justify-content: center;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      .ant-upload {
        margin-left: 24px;
      }
    }

    img {
      border-radius: 8px;
      cursor: pointer;
      height: 100px;
      object-fit: cover;
      width: 100px;
    }
  }
`;

export const CardContainer = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  width: 100%;

  header {
    align-items: flex-start;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    padding: 32px;

    h1 {
      align-items: center;
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
      display: flex;
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: ${convertPxToRem(16)};
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 24px;
    }

    span {
      color: ${({ theme }) => theme.colors.neutralBlack[20]};
      font-family: ${({ theme }) => theme.fonts.texts};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;

      a {
        color: ${({ theme }) => theme.colors.pink[30]};
      }
    }
  }

  .avatar-uploader {
    margin-bottom: 24px;
  }

  .success-text {
    color: #757575;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    padding: 0 32px;
  }

  .ant-tabs {
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      min-height: 630px;
    }
    .ant-tabs-tab {
      cursor: default;
    }
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    color: #757575;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  .ant-select-selection-search-input,
  .ant-select-item-option-content,
  .ant-select-selection-item,
  .ant-select-item,
  .ant-select-item-option-content,
  .ant-select-item-option,
  .ant-input-textarea {
    color: #757575;
    font-family: ${(props) => props.theme.fonts.texts};
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

  .ant-tabs-nav-list {
    margin-left: 24px;
  }

  .ant-tabs-tab {
    .step-checked {
      color: ${({ theme }) => theme.colors.blue[40]};
      font-weight: 700;
      &::after {
        border-bottom: 2px solid ${({ theme }) => theme.colors.blue[20]};
        content: '';
        display: block;
        position: relative;
        top: 12px;
        width: calc(100% + 32px);
      }
    }
  }

  .ant-tabs-tab-btn {
    color: #e9e9e9;
    font-family: ${({ theme }) => theme.fonts.texts};
    font-style: normal;
    font-size: 1rem;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    span {
      &::after {
        border-bottom: 2px solid ${({ theme }) => theme.colors.blue[20]};
        content: '';
        display: block;
        position: relative;
        top: 12px;
        width: calc(100% + 32px);
      }
    }
    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.colors.blue[40]};
      font-weight: 700;
    }
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-content-holder {
    padding: 32px;
  }

  .languages-options {
    > div {
      display: flex;
      flex-flow: row wrap;
    }

    .form-languages {
      margin: 0;
    }
  }

  .tab-footer {
    align-items: flex-end;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding: 32px;
    @media (max-width: ${(props) => props.theme.breakpoints.md}) {
      padding: 0 ${(props) => props.theme.spacing.md};

      .tab-footer--actions {
        display: flex;
        justify-content: space-between;
        flex-flow: column wrap;
        width: 100%;
      }
    }
    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      .tab-footer--actions {
        display: flex;
        gap: 16px;
      }
    }
  }
`;
