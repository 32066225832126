import { DownOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { APP_LANGUAGES } from '../../constants/app-languages';
import { useLanguage } from '../../contexts/languageContext';

import * as S from './styles';

export function FlagMenu() {
  const { languagesOptions, onLanguageMenuClick } = useLanguage();
  const [flagObject, setFlagObject] = useState({});
  const { i18n, t } = useTranslation();

  const renderFlagIcon = (flag) => {
    switch (flag) {
      case 'brazil':
      case 'br':
        return (
          <img
            src="/BRASIL.webp"
            alt="Brazil"
            className="flag-menu__language-flag"
          />
        );

      case 'us':
      default:
        return (
          <img src="/EUA.webp" alt="USA" className="flag-menu__language-flag" />
        );
    }
  };

  useEffect(() => {
    const languageObject = APP_LANGUAGES.find(
      (language) => language.key === i18n?.language
    );
    setFlagObject(languageObject);
  }, [i18n.language]);

  return (
    <S.DropdownFlag
      className="flag-menu"
      menu={{
        items: languagesOptions,
        onClick: (e) => onLanguageMenuClick(e, i18n),
      }}
    >
      <Space>
        {renderFlagIcon(flagObject?.flag)}
        <span className="flag-menu__language">
          {t(`common.languages.${flagObject?.key || 'en-US'}`)}
        </span>
        <DownOutlined />
      </Space>
    </S.DropdownFlag>
  );
}
