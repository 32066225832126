import { useAuth0 } from '@auth0/auth0-react';
import { ContainerApp } from 'components/ContainerApp';
import RedirectLoader from 'components/RedirectingLoader';
import { Outlet } from 'react-router-dom';

export function UnloggedRoute({ children }) {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <RedirectLoader />;
  }

  return <ContainerApp>{children || <Outlet />}</ContainerApp>;
}
