import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import convertPxToRem from 'utils/convert-px-rem';

export const HeaderContent = styled.div`
  background: #08afea;
  height: 650px;
  padding: 24px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    background: transparent;
    padding: 0;
    height: 490px;
    margin-top: 16px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
    height: 495px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
    height: 500px;
  }

  .home-slider__slide {
    background: #08afea;
    img {
      bottom: 0;
      height: 650px;
      margin: 0 auto;
      object-fit: contain;
      position: relative;
      right: 100%;
      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        left: 0;
        right: 0;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        height: 490px;
        float: right;
        position: relative;
      }
    }
  }

  .header-home__container {
    display: none;
    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      display: block;
    }
  }
`;

export const HeaderContainer = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  height: 600px;
  justify-content: space-between;
  position: absolute;
  top: 84px;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    justify-content: center;
    /*
    height: 500px;
    justify-content: center;
    top: 0;
    transform: translate(0, 50%); */
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    align-items: flex-start;
    height: 500px;
    left: 0;
    top: 60px;
    z-index: 1000;
  }

  .ant-form.ant-form-vertical {
    @media (min-width: ${({ theme }) => theme.breakpoints.xxl}) {
      max-width: 1440px;
      margin: 0 auto;
    }
  }

  .about-us__container {
    margin-bottom: 24px;
  }
`;

export const FadeTextContainer = styled.div`
  ${PaddingContainer};
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  gap: 24px;
  justify-content: flex-start;
  margin-top: 24px;
  opacity: ${(props) => (props.showText ? 1 : 0)};
  transition: opacity 1s ease;
  width: 100%;
  z-index: 2;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    margin-top: calc(56px + 24px);
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    width: 1440px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 64px;
    padding-right: 64px;
    align-items: flex-start;
  }

  h1,
  p {
    color: ${({ theme }) => theme.colors.neutralWhite[10]};
    font-family: ${({ theme }) => theme.fonts.texts};
    font-size: ${convertPxToRem(32)};
    font-style: normal;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 32px;
    z-index: 2;
    margin-right: auto;
  }

  p {
    font-size: ${convertPxToRem(20)};
  }
`;
