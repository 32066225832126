import { useAuth0 } from '@auth0/auth0-react';
import { CONTENTA_LOGIN_BTN } from 'constants/storages-values';
import { useEffect } from 'react';

import logo from '../../assets/images/logo.png';
import { Loader } from '../../components/Loader';
import * as S from './styles';

export function Login() {
  const { loginWithRedirect, isLoading } = useAuth0();

  const redirectLogin = async () => {
    await loginWithRedirect();
  };

  if (isLoading) {
    return <Loader />;
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirect = urlParams.get('loginType');
    if (redirect) {
      sessionStorage.removeItem(CONTENTA_LOGIN_BTN);
      sessionStorage.setItem(CONTENTA_LOGIN_BTN, redirect);
    }

    redirectLogin();
  }, []);

  return (
    <S.Container>
      <S.LoginContainer>
        <S.LoginLogo src={logo} alt="Contenta 360 logo" />
        <Loader />
      </S.LoginContainer>
    </S.Container>
  );
}
