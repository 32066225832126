import styled from 'styled-components';
import bgImg from '../../assets/images/background-login.png';

export const Container = styled.main`
  align-items: center;
  background-image: url(${bgImg});
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  min-height: 100vh;
`;

export const LoginContainer = styled.div`
  flex-flow: column wrap;
  background-color: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  display: flex;
  min-width: 444px;
  padding: 42px 42px 16px;
`;

export const LoginLogo = styled.img`
  margin: 0 auto 24px;
  width: 190px;
`;
