import {
  DollarOutlined,
  HistoryOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { BsChatText } from 'react-icons/bs';
import logo from 'assets/images/logo-degrade.webp';
import Avatar from 'components/Avatar';
import { useUser } from 'contexts/userContext';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isUserAdmin, isUserTraveller } from 'utils/isRole';
import * as S from './styles';
import { useEffect, useState } from 'react';
import { toggleEventManager } from 'utils/toggleDrawer';

export function SiderApp() {
  const { user, userRole, logout, whoAmI } = useUser();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  function redirectUrl(url) {
    navigate(url);
    onClose();
  }

  const onClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const adminMenuList = [
    {
      key: 'home',
      icon: <UserOutlined />,
      title: t('sidebar.admin.home'),
      url: '/admin',
    },
    {
      key: 'prices',
      icon: <DollarOutlined />,
      title: t('sidebar.admin.prices'),
      url: '/admin/prices',
    },
  ];

  const travellerMenuList = [
    {
      key: 'search-advisor',
      icon: <UserOutlined />,
      title: t('sidebar.findGuide'),
      url: '/search-advisor',
    },
  ];

  const menuList = [
    {
      key: 'home',
      icon: <UserOutlined />,
      title: 'Home',
      url: isUserTraveller(userRole) ? '/home' : '/advisor/home',
    },
    {
      key: 'historico',
      icon: <HistoryOutlined />,
      title: t('sidebar.history'),
      url: '/history-calls',
    },
    ...(isUserAdmin(userRole)
      ? []
      : [
          {
            key: 'chats',
            icon: <BsChatText className="anticon" />,
            title: 'Chats',
            url: '/chat',
          },
        ]),
    ...(isUserAdmin(userRole) ? adminMenuList : []),
    ...(isUserTraveller(userRole) ? travellerMenuList : []),
  ];

  const accountList = [
    {
      key: 'profile',
      icon: <UserOutlined />,
      title: t('sidebar.profile'),
      url: isUserTraveller(userRole) ? '/profile' : '/advisor/profile',
    },
  ];

  useEffect(() => {
    toggleEventManager.on('toggledrawer', toggleDrawer);

    return () => {
      toggleEventManager.removeListener('toggledrawer', toggleDrawer);
    };
  }, []);

  return (
    <S.LateralMenu
      placement="left"
      onClose={onClose}
      open={open}
      closable={false}
      width={410}
    >
      <Avatar src={logo} alt="Contenta 360" className="lateral-menu__logo" />

      <S.MenuBody>
        {menuList.map((item) => (
          <div
            className="lateral-menu__item"
            key={item.key}
            onClick={() => redirectUrl(item.url)}
          >
            {item.icon}
            <span className="lateral-menu__label">{item.title}</span>
          </div>
        ))}

        <S.Separator />

        <span className="lateral-menu__account">{t('sidebar.account')}</span>

        {accountList.map((item) => (
          <div
            className="lateral-menu__item"
            key={item.key}
            onClick={() => redirectUrl(item.url)}
          >
            {item.icon}
            <span className="lateral-menu__label">{item.title}</span>
          </div>
        ))}
        <div className="lateral-menu__item" onClick={logout}>
          <LogoutOutlined />
          <span className="lateral-menu__label">Logout</span>
        </div>
      </S.MenuBody>

      <S.MenuFooter>
        <Avatar
          src={whoAmI?.imageURL || whoAmI?.picture}
          className="menu-footer__avatar"
        />
        <div className="menu-footer__container">
          <span className="menu-footer__username">{user?.name}</span>
          <span className="menu-footer__email">
            {user?.email?.length > 35
              ? user?.email?.substring(0, 35) + '...'
              : user?.email}
          </span>
        </div>
      </S.MenuFooter>
    </S.LateralMenu>
  );
}
