import { useAuth0 } from '@auth0/auth0-react';
import RedirectLoader from 'components/RedirectingLoader';
import ERRORS_LOGIN from 'constants/errors-options';
import jwt from 'jwt-decode';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { keyRole } from 'utils/isRole';
import isUnloggedRoute from 'utils/isUnloggedRoute';
import { useUser } from '../../contexts/userContext';
import AppRoutes from '../../Routes';

export function AppWrapper() {
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
  const { setToken, handleUserContext, whoAmI } = useUser();
  const navigate = useNavigate();

  async function getNewToken() {
    try {
      const accessToken = await getAccessTokenSilently();
      setToken(accessToken);
      const data = jwt(accessToken);

      const userInfo = sessionStorage.getItem('@contenta-user');
      if (userInfo) {
        const user = JSON.parse(userInfo);
        user.role = data[keyRole];
        sessionStorage.setItem('@contenta-user', JSON.stringify(user));
        handleUserContext(user);
      }
    } catch (error) {
      const isUnloggedPage = isUnloggedRoute(window.location.pathname);
      if (window.location.pathname.startsWith('/advisor-details/')) {
        return;
      }
      if (isUnloggedPage) {
        return;
      }
      if (error.error === ERRORS_LOGIN.CONSENT_REQUIRED) {
        if (isUnloggedPage) {
          return;
        }

        navigate('/login');
      }

      const contentaUserStorage =
        sessionStorage.getItem('@contenta-user') || {};
      if (
        error.error === ERRORS_LOGIN.LOGIN_REQUIRED &&
        !isAuthenticated &&
        !isLoading &&
        Object.keys(contentaUserStorage).length === 0
      ) {
        navigate('/login');
      }
    }
  }

  useEffect(() => {
    const { pathname } = window.location;
    let isUnloggedPage = isUnloggedRoute(pathname);
    if (
      !isAuthenticated &&
      !isLoading &&
      whoAmI?.role === undefined &&
      !isUnloggedPage
    ) {
      navigate('/login');
    }
  }, [isLoading]);

  useEffect(() => {
    if (!isAuthenticated && Object.keys(whoAmI).length > 1) {
      getNewToken();
    }
  }, [isLoading]);

  return isLoading ? <RedirectLoader /> : <AppRoutes />;
}
