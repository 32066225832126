import Avatar from 'components/Avatar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Rating } from '@smastrom/react-rating';
import { Button, Tooltip } from 'antd';
import bgImg from 'assets/images/default-city.webp';
import IconBrazil from 'components/Icons/Flags/brazil';
import FranceIconFlag from 'components/Icons/Flags/france';
import GermanyIconFlag from 'components/Icons/Flags/germany';
import ItalyIconFlag from 'components/Icons/Flags/italy';
import JapanIconFlag from 'components/Icons/Flags/japan';
import KoreaIconFlag from 'components/Icons/Flags/korea';
import PortugalFlagIcon from 'components/Icons/Flags/portugal';
import IconSpain from 'components/Icons/Flags/spain';
import { customStarsStyles } from 'constants/stars-colors';
import { useState } from 'react';
import IconUSA from 'components/Icons/Flags/USA';
import { v4 as uuidv4 } from 'uuid';

import * as S from './styles';

function MentorCard({ mentor }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false);

  const handleRedirectToMentor = () => {
    navigate(`/advisor-details/${mentor.id}`);
  };

  const coverPhotoBg = mentor?.coverURL || bgImg;

  const FlagsCode = {
    'PT-BR': <IconBrazil />,
    'PT-PT': <PortugalFlagIcon />,
    EN: <IconUSA />,
    DE: <GermanyIconFlag />,
    ES: <IconSpain />,
    FR: <FranceIconFlag />,
    IT: <ItalyIconFlag />,
    JA: <JapanIconFlag />,
    KO: <KoreaIconFlag />,
  };

  return (
    <S.MentorCardContainer>
      <div
        className="mentor-card__cover-photo"
        style={{ backgroundImage: `url(${coverPhotoBg})` }}
      />
      <header className="mentor-card__header" onClick={handleRedirectToMentor}>
        <Avatar src={mentor.imageURL} alt={mentor?.name} />
        <div className="mentor-card__info">
          <h3>{mentor?.name}</h3>
          <span className="mentor-card__country">
            {mentor?.address?.city || mentor?.city} -{' '}
            {mentor?.address?.country?.name ||
              mentor?.country ||
              t('home.countryNotInformed')}
          </span>
        </div>
      </header>

      <div className="mentor-card__body">
        <div className="mentor-card__idioms">
          <span className="mentor-card__idioms-text">
            {t('common.idioms')}:
          </span>
          <span className="mentor-card__idioms-options">
            {mentor?.languages?.map(({ code }) => (
              <Tooltip title={t(`languages.code.${code}`)} key={uuidv4()}>
                {FlagsCode[code]}
              </Tooltip>
            ))}
          </span>
        </div>

        <div className="mentor-card__rating">
          <span className="mentor-card__idioms-text">
            {t('mentorProfile.rating')}
          </span>
          <Rating
            className="evaluate-modal__rate-stars"
            spaceBetween={4}
            style={{ maxWidth: 88 }}
            value={mentor?.rating || 0}
            isDisabled
            itemStyles={customStarsStyles}
          />
        </div>
      </div>
      <div className="mentor-card__specialties">
        <span className="mentor-card__specialties-text">
          {t('mentorProfile.specialties')}:
        </span>
        <span className="mentor-card__specialties-options contenta-text">
          {showMore
            ? mentor?.specialties
                ?.map(({ name }) => t(`specialties.${name}`))
                .join(', ')
            : mentor?.specialties
                ?.map(({ name }) => t(`specialties.${name}`))
                .slice(0, 3)
                .join(', ')}
        </span>

        <Button
          type="link"
          className="mentor-card__specialties-link"
          onClick={() => {
            setShowMore(!showMore);
          }}
        >
          {showMore ? t('common.showLess') : t('common.showMore')}
        </Button>
      </div>
    </S.MentorCardContainer>
  );
}

export default MentorCard;
