import styled from 'styled-components';
import PaddingContainer from 'styles/paddingContainer';
import bgImg from 'assets/images/traveller/background-traveller.webp';
import commonTexts from 'styles/commonTexts';
import convertPxToRem from 'utils/convert-px-rem';

export const Container = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  background-image: url(${bgImg});
  padding: 32px 0;
  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
  }
`;

export const Header = styled.header`
  ${PaddingContainer};
  display: flex;
  flex-flow: column;

  @media (min-width: ${(props) => props.theme.breakpoints.md}) {
    align-items: center;
    flex-flow: row;
    gap: 32px;
    justify-content: flex-start;
  }

  .header__logo {
    width: 250px;
    margin-bottom: 8px;
  }

  .header__forum {
    position: relative;
    top: 5px;
    width: 140px;
  }
`;

export const Content = styled.div`
  background: ${(props) => props.theme.colors.light};
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 32px 24px;

  /* @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    width: 600px;
  } */

  @media (min-width: ${(props) => props.theme.breakpoints.xl}) {
    margin-top: 48px;
  }

  .flag-menu {
    align-self: flex-end;

    .ant-space-item {
      display: flex;
    }
  }

  .form__header {
    display: flex;
    flex-direction: row nowrap;
    justify-content: space-between;

    h2 {
      font-family: ${(props) => props.theme.fonts.texts};
      font-style: normal;
      font-weight: 800;
      font-size: ${convertPxToRem(16)};
      line-height: 20px;

      align-items: center;
      letter-spacing: 1.25px;
      font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
      color: ${(props) => props.theme.colors.neutralBlack[20]};
    }

    p {
      ${commonTexts.CommonParagraph};
    }
  }

  .traveler-register__actions {
    align-items: center;
    display: flex;
    justify-content: flex-end;

    .ant-row {
      width: 100%;
    }

    .ant-form-item-control-input-content {
      align-items: flex-end;
      display: flex;
      flex-flow: row nowrap;
      gap: 8px;
      justify-content: flex-end;
      width: 100%;
    }
  }
`;

export const Notification = styled.div`
  width: 100%;
  margin-top: 32px;

  @media (min-width: ${(props) => props.theme.breakpoints.lg}) {
    ${PaddingContainer};
    bottom: 24px;
    position: absolute;
  }

  .notification__content {
    align-items: center;
    background: rgba(58, 0, 158, 0.8);
    border-radius: 8px;
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    padding: 16px;
    gap: 16px;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      flex-flow: row nowrap;
      padding: 20px 24px;
    }
  }

  .notification__paragraph {
    color: ${(props) => props.theme.colors.neutralWhite[10]};
    font-family: ${(props) => props.theme.fonts.texts};
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-size: ${convertPxToRem(16)};
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1.25px;
    line-height: 22px;
  }

  .ant-btn {
    width: 100%;

    @media (min-width: ${(props) => props.theme.breakpoints.md}) {
      width: auto;
    }
  }
`;

export const StyledRegisterCompleted = styled.div`
  h1 {
    font-family: ${(props) => props.theme.fonts.texts};
    font-style: normal;
    font-weight: 800;
    font-size: ${convertPxToRem(16)};
    color: ${(props) => props.theme.colors.neutralBlack[20]};
    margin-bottom: 16px;
  }

  p {
    font-family: ${(props) => props.theme.fonts.texts};
    font-style: normal;
    font-weight: 400;
    font-size: ${convertPxToRem(16)};
    color: ${(props) => props.theme.colors.neutralBlack[20]};
  }
`;
