import { Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { COUNTRIES_LIST } from 'constants/countries';

const useConsultancyInputs = () => {
  const { t } = useTranslation();
  const consultancyInputs = [
    {
      name: 'consultancyCountry',
      col: 8,
      label: t('guides.country'),
      isRequired: true,
      hasFeedback: true,
      message: t('guides.countryRequired'),
      input: (
        <Select
          aria-autocomplete="none"
          allowClear
          showSearch
          optionFilterProp="name"
          filterOption={(input, option) =>
            option.children
              .toLocaleLowerCase()
              .includes(String(input).toLocaleLowerCase())
          }
        >
          {COUNTRIES_LIST.map((country) => (
            <Select.Option key={country.id} value={country.id}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      name: 'consultancyState',
      label: t('guides.state'),
      col: 8,
      isRequired: true,
      hasFeedback: true,
      message: t('guides.stateRequired'),
    },
    {
      name: 'consultancyCity',
      label: t('guides.city'),
      col: 8,
      isRequired: true,
      hasFeedback: true,
      message: t('guides.cityRequired'),
    },
    {
      name: 'consultancyDetails',
      label: t('guides.consultancyDetails'),
      col: 24,
      isRequired: true,
      hasFeedback: false,
      className: 'initial-font-transform',
      message: t('guides.chooseAOptionRequired'),
      input: (
        <Input.TextArea
          rows={4}
          placeholder=""
          maxLength={500}
          showCount
          className="h-auto"
        />
      ),
    },
  ];

  return {
    consultancyInputs,
  };
};

export default useConsultancyInputs;
