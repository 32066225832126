/* eslint-disable react/jsx-props-no-spreading */
import { Auth0Provider } from '@auth0/auth0-react';
import { SearchProvider } from 'contexts/searchContext';
import { ThemeProvider } from 'styled-components';
import { LanguageProvider } from '../../contexts/languageContext';
import { UserProvider } from '../../contexts/userContext';
import GlobalStyle from '../../styles/globalStyles';
import defaultTheme from '../../styles/themes/default';
import { AppWrapper } from '../AppWrapper';
import TagManager from 'react-gtm-module';
import { CONTENTA_REDIRECT } from 'constants/storages-values';
const tagManagerArgs = {
  gtmId: 'AW-11097362479',
};

TagManager.initialize(tagManagerArgs);

function App() {
  const localStorageLanguage = sessionStorage.getItem('@contenta-language');
  const { pathname, search } = window.location;
  const urlsToNotSave = [
    '/login',
    '/meet/*',
    '/guide/register',
    '/register',
    '/redirecting',
    '/',
    '/faq',
    '/privacy-policy',
    '/terms-and-conditions',
  ];
  const hasContentaRedirect = sessionStorage.getItem(CONTENTA_REDIRECT);
  if (
    (pathname.includes('/advisor-details/') ||
      !urlsToNotSave.includes(pathname)) &&
    !hasContentaRedirect
  ) {
    sessionStorage.setItem(CONTENTA_REDIRECT, `${pathname}${search}`);
  }

  const providerConfig = {
    audience: process.env.REACT_APP_AUDIENCE,
    clientId: process.env.REACT_APP_CLIENT_ID,
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    redirectUri: `${window.location.origin}/redirecting`,
    useRefreshTokens: true,
    cacheLocation: 'localstorage',
  };

  const enforceHttps = () => {
    if (window.location.protocol !== 'https:') {
      window.location.href =
        'https:' +
        window.location.href.substring(window.location.protocol.length);
    }
  };

  if (process.env.NODE_ENV !== 'development') {
    enforceHttps();
  }

  return (
    <Auth0Provider {...providerConfig}>
      <UserProvider>
        <LanguageProvider language={localStorageLanguage}>
          <SearchProvider>
            <ThemeProvider theme={defaultTheme}>
              <GlobalStyle />
              <AppWrapper />
            </ThemeProvider>
          </SearchProvider>
        </LanguageProvider>
      </UserProvider>
    </Auth0Provider>
  );
}

export default App;
