import PropTypes from 'prop-types';

function WineGastronomy({ color, height, width }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width={70}
      height={58}
      style={{
        position: 'relative',
        top: '-5px',
      }}
      viewBox="644 1333 1166 806"
    >
      <path
        d="M1166.8 2120.47c-9.13 0-16.53-7.41-16.53-16.53 0-9.13 7.4-16.54 16.53-16.54h418.9c9.13 0 16.54 7.41 16.54 16.54 0 9.12-7.41 16.53-16.54 16.53h-418.9zm-339.46 0c-10.74 0-19.45-7.4-19.45-16.53 0-9.14 8.71-16.54 19.45-16.54h112.58v-261.91c-101.08-4.29-154.91-49.6-175.97-124.86-20.83-74.44-7.32-178.21 24.59-300.03 1.42-7.69 8.16-13.52 16.26-13.52h302.53c8.1 0 14.84 5.83 16.26 13.51 31.91 121.82 46.6 225.54 26.36 299.98-20.42 75.12-74 120.41-176.69 124.89v261.94h111.43c10.75 0 19.46 7.4 19.46 16.54 0 9.13-8.71 16.53-19.46 16.53H827.34zm290.71-428.56c7.86-28.91 9.8-63.28 7.08-102.18H787.77c-2.4 38.87-.14 73.22 7.95 102.12 17.86 63.81 66.53 100.91 160.34 100.91 95.96 0 144.65-37.08 161.99-100.85zm3.82-135.24c-5.19-41.49-14.72-87.3-27.3-136.52H817.56c-12.55 49.22-21.86 95.03-26.76 136.52h331.07zm287 420.49c-17.53-34.48-38.07-60.13-60.14-77.35-23.67-18.47-49.15-27.19-74.42-26.64h-.65c-25.36-.55-50.96 8.27-74.77 26.97-21.99 17.27-42.42 42.82-59.81 77.02h269.79zm-306.35 0 1.32-2.86 2.08-4.36c20.46-42.63 45.35-74.39 72.56-95.76 29.9-23.48 62.61-34.59 95.51-33.95 32.68-.64 65.22 10.36 95.02 33.62 18.79 14.66 36.46 34.21 52.32 58.79 11.18-16.13 23.58-28.9 36.72-38.4 19.94-14.41 41.49-21.22 63.17-20.82 23.89-.44 47.6 7.86 69.15 25.38 19.28 15.69 36.78 38.81 50.92 69.66l2.84 7.03.67 1.67h62.93c9.18 0 16.62 7.44 16.62 16.61 0 9.17-7.44 16.61-16.62 16.61h-662.96c-9.17 0-16.61-7.44-16.61-16.61 0-9.17 7.44-16.61 16.61-16.61h57.75zm337.24-11.99c.57 1.15 1.14 2.3 1.71 3.47.9 1.87 2.51 5.42 3.91 8.52h163.58c-11.52-23.75-25.02-41.37-39.52-53.16-15.29-12.45-31.7-18.32-47.89-17.96h-.65c-14.66-.32-29.49 4.44-43.48 14.55-13.54 9.77-26.36 24.55-37.66 44.58z"
        style={{
          fill: '#8d8b8d',
          fillRule: 'nonzero',
        }}
      />
    </svg>
  );
}

WineGastronomy.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
};

WineGastronomy.defaultProps = {
  width: 53,
  height: 40,
  color: '#868484',
};

export default WineGastronomy;
